<template>
    <div
        class="formSelectMulti"
        v-click-outside="close"
        :class="{open: show}">

        <label
            v-if="label"
            class="form-label">
            {{ label }}
        </label>

        <div
            class="form-select"
            :class="{ 'form-select-borderless': borderless }"
            @click="toggle">

            <div class="multiple_items" v-if="multiple" >
                <span
                    class="multiple_item"
                    v-if="selected.length"
                    v-for="(selectedItem, index) in selected" >
                    
                    {{ selectedItem.name || selectedItem.title }}
                    
                    <div @click.prevent="removeFromMultiple(index)" class="delete_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                </span>
                <span class="formSelectMulti__placeholder" v-if="!selected.length">
                    {{ placeholder }}
                </span>
            </div>

            <div class="formSelectMulti__placeholder" v-if="!multiple">
                <span class="formSelectMulti__selected" v-if=selected.length>{{ selected[0].name || selected[0].title }}</span>
                <span v-if=!selected.length>{{ placeholder }}</span>
            </div>

            <span class="dropdown-caret">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="50" viewBox="0 0 24 24">
                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                </svg>
            </span>
        </div>
        
        <ul class="form-select-dropdown">
            <div
                v-if="!hiddenSearchAndReset"
                class="form-select-dropdown__search">
                <input
                    autocomplete="nope"
                    type="text"
                    v-model="searchQuery"
                    :placeholder="$lng.__('lk_webmaster_main', 'Search')">
            </div>
            <li
                v-if="!hiddenSearchAndReset"
                @click="clear"
                class="dropdown-item formSelectMulti__reset">
                {{ $lng.__('lk_webmaster_main', 'Reset') }}
            </li>
            <li
                v-if="hiddenSearchAndReset"
                class="dropdown-item formSelectMulti__no-options">
                {{ $lng.__('lk_webmaster_main', 'No options') }}
            </li>
            <template  v-for="option in optionsFiltered">
                <li
                    v-if="optionVisible(option)"
                    @click="select(option)"
                    class="dropdown-item"
                >
                    {{ option.name }}
                </li>
            </template>
        </ul>
        
    </div>
</template>

<script>
    export default {
        name: "FormSelectMulti",
        props: {
            label: {
                type: String,
                default: '',
            },
            selected: {
                type: Array,
                reguired: true,
            },
            options: {},
            except: {},
            placeholder: {
                type: String,
                default: '',
            },
            danger: {},
            borderless: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            nested: {
                type: Boolean,
                default: false,
            }
        },
        emits: [
            'update:selected'
        ],
        data() {
            return {
                show: false,
                searchQuery: ""
            };
        },
        computed: {
            exceptedIds() {
                let exceptedIds = [];

                /*_.each(this.except, item => {
                    _.isArray(item)
                        ? _.each(item, subitem => {
                            exceptedIds.push(subitem);
                        })
                        : exceptedIds.push(item);
                });*/
                return exceptedIds;
            },
            optionsFiltered() {
                return this.options.filter(option => {
                    let pass = true

                    if (option.name && this.searchQuery) {
                        pass = option.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1
                    }

                    /*if (pass && this.except) {
                        pass = this.exceptedIds.indexOf(option.id) < 0
                    }*/

                    /*if (pass && this.selected) {
                        pass = this.selected.filter(item => {
                            return item.id === option.id
                        }).length > 0
                    }*/

                    return pass
                });
            },
            hiddenSearchAndReset() {
                return !this.options.length
            }
        },
        methods: {
            toggle() {
                if (this.show) {
                    this.show = false
                } else {
                    this.show = true
                    this.focusInput()
                    this.$el.getElementsByClassName('form-select-dropdown')[0].scrollTop = 0
                }
            },
            open() {
                this.show = true
            },
            close() {
                this.show = false
            },
            clear() {
                this.show = false
                this.$emit('update:selected', [])
                this.$emit('changed')
            },
            select(option) {
                this.show = false
                this.searchQuery = ''

                if (!this.multiple) {
                    this.$emit('update:selected', [option])
                    this.$emit('changed')
                } else {
                    this.$emit('update:selected', [...this.selected, option])
                    this.$emit('changed')
                }
            },
            getOptionById(id) {
                return this.options.find(option => {
                    return option.id === id
                });
            },
            removeFromMultiple(index) {
                this.selected.splice(index, 1)
                this.show = false
            },
            focusInput() {
                if (!this.hiddenSearch) {
                    this.$el.querySelector("input").focus()
                }
            },
            getChildsOfDropdownItem(item) {
                if (this.nested) {
                    return this.options.filter(option => {
                        return parseInt(option.parent) === parseInt(item.id)
                    })
                } else {
                    return this.options
                }
            },
            optionVisible(option) {
                let passFilter = true

                if (option.name && this.searchQuery) {
                    passFilter = option.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1
                }

                if (passFilter && this.except) {
                    passFilter = this.exceptedIds.indexOf(option.id) !== -1
                }

                if (passFilter && this.selected) {
                    passFilter = !collect(this.selected).contains('id', option.id)
                }

                let childOptions = this.getChildsOfDropdownItem(option)

                if (!passFilter && childOptions.length && this.searchQuery) {
                    passFilter = childOptions.filter(childOption => {
                        return childOption.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1
                    }).length > 0
                }

                return passFilter
            },
        },
        beforeMount() {
            if (this.value === 0 || this.value) {
                if (this.multiple) {
                    this.selected = [];
                    this.value.forEach(item => {
                        this.selected.push(this.getOptionById(item));
                    })
                } else {
                    this.selected = typeof this.value == "object"
                        ? this.getOptionById(this.value.id)
                        : this.getOptionById(this.value);
                }
            }
        }
    };
</script>

<style lang="scss">

    @import './../scss/utils/mixins.scss';
    @import './../scss/utils/variables.scss';

    $formSelectHeight: 37px;
    $formElementBorderColor: #f4f4f4;
    $color-text-muted: #999;
    $dropdown-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    $formElementBgActive: #f9fafc;
    $brand-danger: #bf5329;

    .formSelectMulti{
        width: 100%;
        position: relative;
        height: 50px;
        &__placeholder{
            font-size: 14px;
            color: $color-day-gray;
            white-space: nowrap;
        }
        &__selected{
            color: $color-text-default;
        }
        &__reset{
            color: $color-day-gray !important;
        }
    }

    .form-label {
        position: absolute;
        bottom: 100%;
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 14px;
        color: $color-text-default;
        margin-bottom: 2px;
        letter-spacing: 0.5px;
    }

    .form-select {
        width: 100%;
        display: block;
        min-height: $form-control-height - 2px;
        padding: 0 0 0 20px;
        line-height: $form-control-height - 2px;
        border: 1px solid $color-day-line;
        position: relative;
        background: white;
        font-size: 15px;
        border-radius: 3px;
        z-index: 20;
        font-family: $font-secondary;
        transition: all 0.12s linear;
        .multiple_items{
            height: $form-control-height - 2px;
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow-x: auto;
            flex-wrap: nowrap;
            margin-right: 50px;
            margin-left: -10px;
            @extend .noScrollbar;
        }
        .multiple_item {
            font-size: 15px;
            background: $color-day-table;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            line-height: 30px;
            padding: 2px 5px 2px 7px;
            border-radius: 3px;
            margin: 0 10px 0 0;
            white-space: nowrap;
            .delete_icon{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 0 3px;
                padding-top: 2px;
                opacity: .5;
                svg{
                    height: 14px;
                    width: 14px;
                }
                &:hover{
                    opacity: 1;
                }
            }
        }
        .dropdown-caret {
            position: absolute;
            right: 10px;
            top: 0;
            font-size: 20px;
            color: $color-day-dark-gray2;
            transition: all 0.12s linear;
        }

        &:hover {
            border: 1px solid $color-day-line;
            cursor: pointer;
        }
    }

    .form-select-borderless {
        border-color: transparent;
        .dropdown-caret {
            color: transparent;
        }
        &:hover {
            .dropdown-caret {
                color: $color-text-default;
            }
        }
    }

    .form-select-dropdown {
        position: absolute;
        top: 100%;
        background: white;
        border: 0;
        width: 100%;
        overflow: auto;
        z-index: 999;
        max-height: 0;
        transition: all 50ms ease;
        padding: 0;
        box-shadow: $dropdown-shadow;
        .dropdown-item{
            padding: 0 10px;
            line-height: 30px;
            font-size: 14px;
            font-family: $font-secondary;
            color: $color-text-default;
            white-space: nowrap;
            &:not(.dropdown-item.formSelectMulti__no-options):hover {
                cursor: pointer;
                background: $formElementBgActive;
                color: $color-text-default;
            }
            &-disabled{
                color: $color-text-muted;
                cursor: not-allowed;
                &:hover{
                    cursor: not-allowed;
                    color: $color-text-muted;
                    background: transparent;
                }
            }
        }
        &__search {
            padding-bottom: 5px;
            input {
                width: 100%;
                font-family: $font-secondary;
                font-size: 14px;
                line-height: 30px;
                border: 0;
                border-bottom: 1px solid $color-day-line;
                padding: 5px 10px;
                &, &:active, &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .open {
        .form-select {
            border: 1px solid $color-day-line;
            background: $formElementBgActive;

            .dropdown-caret {
                color: $color-text-default;
            }
        }

        .form-select-dropdown {
            max-height: 230px;
            list-style: none;
        }
    }

    .form-error {
        .form-label {
            color: $brand-danger;
        }
        .form-input {
            border: 1px solid $brand-danger;
        }
    }

</style>
