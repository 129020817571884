<template>
    <div
        v-if="isVisible"
        class="AlertAccountNotActivated">
        <Alert
            v-if="true"
            addclass=""
            :message="$lng.__('lk_webmaster_errors', 'Account not activated')"
        ></Alert>
    </div>
</template>

<script>
export default {
    name: 'AlertAccountNotActivated',
    props: [],
    data() {
        return {};
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
        isVisible() {
            return this.$store.getters['auth/isUserLoaded']
                && !this.$store.getters['auth/isUserApproved']
        },
    },
    watch: {
        user: {
            deep: true,
            handler: (user, oldUser) => {
                let accountApprooved = oldUser !== false
                    && !oldUser.is_approved
                    && user.is_approved;

                if (accountApprooved) {
                    WV.toast.success('Account successfully activated!', 5000);
                }
            }
        },
    },
    methods: {
        reloadUserData() {
            this.$store.dispatch('auth/getUser');
            if (!this.$store.getters['auth/isUserLoaded'] || !this.$store.getters['auth/isUserApproved']) {
                setTimeout(this.reloadUserData, 10000);
            }
        },
    },
    mounted() {
        this.reloadUserData();
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.AlertAccountNotActivated {
    width: 100%;
    margin-top: 90px;
    margin-bottom: -70px;
    @include break(md) {
        margin-top: 0;
        margin-bottom: 20px;
    }
}
</style>