<template>
  <Modal :title="false" :width="700" v-if="isModalVisible" @close="close()">
    <div class="AlertModal">
      <h3 class="AlertModal__title">
        {{ $lng.__('lk_webmaster_main', 'Уважаемый партнер') }}
      </h3>
      <div class="AlertModal__content">
        {{ $lng.__('lk_webmaster_main', 'С вашего аккаунта') }}&nbsp;<span
          class="AlertModal__alert"
          >{{ this.user.id }}</span
        >&nbsp;
        {{ $lng.__('lk_webmaster_main', 'идут лиды') }}
        <ol class="AlertModal__list">
          <li v-for="(offer, index) in this.nonActiveOffer" :key="index">
            <p class="AlertModal__list-content">
              {{ $lng.__('lk_webmaster_main', 'на неактивное гео') }}&nbsp;<span
                class="AlertModal__flag-icon"
              >
                <SvgUse
                  :width="20"
                  :height="20"
                  :id="`geo${offer.countryCode}`"
                  key="icon-alert"
                ></SvgUse> </span
              >&nbsp;
              <span class="AlertModal__alert">
                {{ offer.countryName }}
              </span>
            </p>
            <p class="AlertModal__list-content">
              <span>
                &nbsp;
                {{ $lng.__('lk_webmaster_main', 'на оффере') }} &nbsp;
              </span>

              <img
                :src="offer.offerIMG"
                :alt="offer.offerName"
                class="AlertModal__offer-icon"
              />
              <span class="AlertModal__alert">
                {{ offer.offerName }} (id: {{ offer.offerID }})</span
              >
            </p>
            <h4>
              {{ $lng.__('lk_webmaster_main', 'Просим проверить ваш трафик.') }}
            </h4>
            <p class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'Дата создания лида:') }}
              <br />
              <span class="AlertModal__alert AlertModal__content-text">
                {{ offer.timestamp }}
              </span>
            </p>
            <p class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'Lead guid:') }}
              <br />
              <span class="AlertModal__alert AlertModal__content-text">
                {{ offer.leadGuid }}
              </span>
            </p>
            <p v-if="offer.utm" class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'UTM Source:') }} &nbsp;
              <span class="AlertModal__alert">
                {{ offer.utm.source }}
              </span>
            </p>
            <p v-if="offer.utm" class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'UTM Medium:') }} &nbsp;
              <span class="AlertModal__alert">
                {{ offer.utm.medium }}
              </span>
            </p>
            <p v-if="offer.utm" class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'UTM Campaign:') }} &nbsp;
              <span class="AlertModal__alert">
                {{ offer.utm.campaign }}
              </span>
            </p>
            <p v-if="offer.utm" class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'UTM Content:') }} &nbsp;
              <span class="AlertModal__alert">
                {{ offer.utm.content }}
              </span>
            </p>
            <p v-if="offer.utm" class="AlertModal__content-text">
              {{ $lng.__('lk_webmaster_main', 'UTM Term:') }} &nbsp;
              <span class="AlertModal__alert">
                {{ offer.utm.term }}
              </span>
            </p>
          </li>
        </ol>
        <h4 class="AlertModal__content-text">
          {{ $lng.__('lk_webmaster_main', 'С уважением, команда Webvork') }}
        </h4>
      </div>
      <div class="AlertModal__footer">
        <button @click="close()" class="wv-btn--blue wv-btn--text">
          {{ $lng.__('lk_webmaster_main', 'Окей я понял') }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
  export default {
    name: 'AlertModal',
    props: [],
    data() {
      return {
        isModalVisible: false,
        nonActiveOffer: [],
      };
    },
    computed: {
      user() {
        return this.$store.state.auth.user;
      },
    },
    methods: {
      close() {
        this.isModalVisible = false;
      },
      showModal() {
        if (this.user.traffic_alert.alert_quantity > 0) {
          if (this.user.traffic_alert.alert_quantity != this.getCookies()) {
            this.setCookies(this.user.traffic_alert.alert_quantity);
            this.getNonActiveOffer(this.user.traffic_alert);
            this.isModalVisible = true;
          }
        }
      },
      getNonActiveOffer(trafficAlert) {
        let nonActiveOffers = [];
        trafficAlert.alert_offer.forEach((offer) => {
          let offerItem = {};

          offerItem.offerID = offer.offer_id;
          offerItem.offerName = offer.offer_name;
          offerItem.offerIMG = `https://minio.cdnwv.com/cdnwv/success/it/${offer.offer_id}/success-product.png`;
          offerItem.countryName = offer.geo_name;
          offerItem.countryCode = offer.geo_code;
          offerItem.timestamp = offer.timestamp;
          offerItem.leadGuid = offer.lead_guid;
          offerItem.utm = offer.utm;

          nonActiveOffers.push(offerItem);
        });
        this.nonActiveOffer = nonActiveOffers;
      },
      setCookies(alert_quantity = 0) {
        const date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + date.toUTCString();
        document.cookie = `user_traffic_alert=${alert_quantity}; ${expires};`;
      },
      getCookies(name = 'user_traffic_alert=') {
        if (decodeURIComponent(document.cookie).search(`${name}`) != -1) {
          return decodeURIComponent(document.cookie)
            .split(`; ${name}`)
            .pop()
            .split(';')
            .shift()
            .slice(`${name}`.length);
        } else {
          return 0;
        }
      },
    },
    watch: {
      user: {
        deep: true,
        handler(user, oldUser) {
          this.showModal();
        },
      },
    },
  };
</script>

<style lang="scss">
  @import '../../../scss/utils/mixins.scss';
  @import '../../../scss/utils/variables.scss';

  .AlertModal {
    font-family: $font-primary;
    margin: -20px;
    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.1;
      letter-spacing: 0.005em;
      text-align: center;
      padding: 40px 20px;
      margin: 0 auto;
      display: block;
      transform: none;
      border-bottom: 1px solid rgba(237, 241, 245, 1);
      @include maxWidth(720) {
        padding: 20px 10px;
      }
    }
    &__content {
      font-size: 16px;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 40px;
      position: relative;
      z-index: 3;
      text-align: center;
      max-width: 480px;
      margin: 0 auto;
      padding: 40px 0 20px;
      @include maxWidth(720) {
        padding: 20px 0;
      }
    }
    h4 {
      margin: 0;
      padding: 10px 0;
    }
    &__list {
      padding: 5px 0;
      li{
        padding: 5px 0 10px;
        border-bottom: 1px solid rgba(237, 241, 245, 1);
        margin: 0 0 10px;
      }
    }
    &__list-content {
      display: flex;
      flex: wrap;
      justify-content: center;
      align-items: center;
      padding: 2px 0;
      width: 100%;
    }
    &__content-text {
      display: block;
      padding: 5px 0;
    }
    &__alert {
      font-weight: bold;
      color: #00d199;
      text-decoration-line: underline;
    }
    &__offer-icon {
      max-width: 35px;
      max-height: 35px;
    }
    &__flag-icon {
      max-width: 35px;
      max-height: 35px;
      margin-right: 5px;
    }
    &__footer {
      border-top: 1px solid rgba(237, 241, 245, 1);
      padding: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      @include maxWidth(720) {
        padding: 20px 0;
      }
    }
  }
</style>
