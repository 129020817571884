/* Global Variable */
window.WV = {
    build: 2,
    components: {},
    domainZone: document.location.hostname.split('.')[document.location.hostname.split('.').length - 1],
    env: {
        isLocal: process.env.NODE_ENV !== "production",
        isStage: (document.location.hostname.split('.')[document.location.hostname.split('.').length - 1]) === 'ru',
        isProduction: process.env.NODE_ENV === "production",
    },
    language_namespace: 'lk_webmaster',
    copyTextToClipboard: (text) => {
        return new Promise((resolve, reject) => {
            const el = document.createElement('textarea')
            el.value = text
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
            resolve()
        })
    },
    isMobile() {
        return window.innerWidth < 1000;
    },

    isUrlLangValid(lng) {
        return ['ru', 'en', 'de', 'es', 'it'].indexOf(lng) !== -1
    },
    getUrlParamByName(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    },
    getUrlSearchParams() {
        return window.location.search;
    },
    isAuthRoute() {
        return window.location.pathname.indexOf('/auth/') !== -1
    },
    is404Route() {
        return WV.router.currentRoute.value.name === '404'
    },
};

Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.getFormattedDate = function() {
    let d = new Date(this.valueOf());
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-');
}

import '@/scss/main.scss';
import collect from 'collect.js';
/* HTTP Client */
import axios from 'axios';
/* API */
import Api from './api';
/* EventBus */
import mitt from 'mitt';
/* Translations API */
import TranslationsApi from './TranslationsAPI';
/* Router */
import router from './router';
/* Store */
import store from './store/index';
import Lng from './Lng';
import tippy from 'tippy.js';
import Toastify from 'toastify-js';
/* Register Components */
import DateRangePicker from './components/DateRangePicker/index';
import Webmaster from './views/Webmaster/Webmaster.vue';
import Loading from './components/Loading.vue';
import Alert from './components/Alert.vue';
import SvgUse from './components/SvgUse.vue';
import SortingIcons from './components/SortingIcons.vue';
import Sidebar from './components/Sidebar.vue';
import SidebarItem from './components/SidebarItem.vue';
import ScrollUp from './components/ScrollUp.vue';
import FormInput from './components/FormInput.vue';
import FormTextarea from './components/FormTextarea.vue';
import FormRadio from './components/FormRadio.vue';
import FormSelect from './components/FormSelect.vue';
import FormSelectMulti from './components/FormSelectMulti.vue';
import FormSwitch from './components/FormSwitch.vue';
import Card from './components/Card.vue';
import Modal from './components/Modal.vue';
import AvatarLetter from './components/AvatarLetter.vue';
import InView from './components/InView.vue';
import ShowMore from './components/ShowMore.vue';

/* Make App */
import {createApp} from 'vue';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';

Object.defineProperty(Object.prototype, 'filterBy', {
    value: function(payload) {
        let field = Object.keys(payload)[0];
        let value = Object.values(payload)[0];
        return this.filter(el => el[field] === value);
    }
})

window.collect = collect

window.axios = axios;

WV.Api = new Api()

WV.Bus = mitt()

WV.TranslationsApi = new TranslationsApi()

WV.router  = router

WV.store = store

WV.Lng = Lng.mount('#lng')

WV.tippy = tippy
WV.tippy.setDefaultProps({
    delay: 50,
    theme: 'light'
});
WV.tippyMount = () => {
    WV.tippy('[data-tippy-content]')
}
WV.remountTippy = () => {
    setTimeout(WV.tippyMount, 1000);
}

WV.toast = {
    success: (message, duration = 3000) => {
        Toastify({
            text: message,
            duration: duration,
            close: false,
            gravity: "bottom",
            position: "center",
            backgroundColor: "linear-gradient(to right, #0e9472, #44dab3)",
            stopOnFocus: true,
            onClick: () => {}
        }).showToast();
    },
    error: (message, duration = 3000) => {
        Toastify({
            text: message,
            duration: duration,
            close: true,
            gravity: "top",
            position: "right",
            backgroundColor: "linear-gradient(to right, #ff0100, #c90706)",
            stopOnFocus: true,
            onClick: () => {}
        }).showToast();
    }
}




WV.validations = {
    isEmailValid: (email) => {
        let isEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
        if (!isEmailValid) {
            return false;
        }
        return true;
    },
    isPhoneValid: (phone) => {
        let cleanPhone = phone.replace(/\D+/g, '');
        let isPhoneValid = /^\+?[1-9][0-9]{7,14}$/.test(cleanPhone);
        if (!isPhoneValid) {
            return false;
        }
        return true;
    },
    isOnlyLettersAndNumbers: (value) => {
        return /^[0-9a-zA-Z]+$/.test(value);
    },
    isOnlyNumbers: (value) => {
        return /^[0-9]+$/.test(value);
    },
    isStringNotEmpty: (string) => {
        if (string === null) return false;
        return String(string).trim().length >= 1;
    },
    isNumberSmallThan: (number, max) => {
        return parseInt(number) < parseInt(max);
    },
    isStringLengthSmallThan: (string, max) => {
        if (string === null) return true;
        return string.length < parseInt(max);
    },
    isStringLengthBiggerThan: (string, min) => {
        if (string === null) return false;
        return string.length > parseInt(min);
    },
    isNumberValid: (val) => {
        return !isNaN(val)
    },
    isDomainNameValid: (domain) => {
        if (domain.length === 0) {
            return true;
        } else {
            return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain);
        }
    },
    isURLNameValid: (url) => {
        if (url === null || url.length == 0) {
            return true;
        } else if (url.match(/(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}/g)) {
            return true;
        } else {
            return false
        }
    },
};

WV.app = createApp(Webmaster)
    .use(WV.router)
    .use(store)
    .component('AvatarLetter', AvatarLetter)
    .component('Loading', Loading)
    .component('Alert', Alert)
    .component('SvgUse', SvgUse)
    .component('Sidebar', Sidebar)
    .component('SidebarItem', SidebarItem)
    .component('ScrollUp', ScrollUp)
    .component('FormInput', FormInput)
    .component('FormTextarea', FormTextarea)
    .component('FormRadio', FormRadio)
    .component('FormSelect', FormSelect)
    .component('FormSelectMulti', FormSelectMulti)
    .component('FormSwitch', FormSwitch)
    .component('Card', Card)
    .component('Modal', Modal)
    .component('SortingIcons', SortingIcons)
    .component('DateRangePicker', DateRangePicker)
    .component('InView', InView)
    .component('ShowMore', ShowMore)

WV.app.directive('click-outside', {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value();
            }
        }
        document.addEventListener("click", el.clickOutsideEvent)
    },
    unmounted: el => { document.removeEventListener("click", el.clickOutsideEvent) },
})

WV.app.config.globalProperties.$lng = WV.Lng;

WV.app.config.globalProperties.$format = {
    price: function(value) {
        let intValue = parseInt(value);
        let locale = WV.store.state.auth.user.language || 'ru';
        return new Intl.NumberFormat(locale, { maximumSignificantDigits: 3 }).format(intValue);
    },
};

// Шорт для валидаций
WV.app.config.globalProperties.$validations = WV.validations

WV.app.config.globalProperties.$isMobile = function() {
    return window.innerWidth < 1000
}

WV.app.config.globalProperties.$config = {
    baseUrl: document.location.protocol+'//webvork.'+WV.domainZone,
    languageShort: localStorage.getItem('lng_code') !== null ? localStorage.getItem('lng_code') : (WV.Lng.isLngCodeValid(WV.Lng.getLngCodeFromUrl) ? WV.Lng.getLngCodeFromUrl : 'en'),
}
WV.app.config.globalProperties.$tippy = WV.tippy
WV.app.config.globalProperties.$toast = WV.toast



Sentry.init({
    app: WV.app,
    dsn: "https://34c11ee77fe9549c598a20ad745baf15@sentry.webvork.site/22",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    tracesSampleRate: 0.25,
});

WV.app.mount('#app')

document.addEventListener("DOMContentLoaded", function () {
    setTimeout(WV.tippyMount, 3000)
})
