<template>
    <Sidebar
        lngNamespace="lk_webmaster_main_menu"
        :elements="elements"
    ></Sidebar>
</template>

<script>
    export default {
        name: 'WebmasterSidebar',
        computed: {
            elements() {
                return this.$store.state.webmaster.menu;
            }
        },
    
    };
</script>
