export default {
    namespaced: true,
    state: () => ({
        isShopAvailable: true,
        products: [],
        taxons: [],
        product: false,
        productsRelated: [],
        orders: [],
        order: {
            contacts: {
                first_name: '',
                second_name: '',
                middle_name: '',
                phone: '',
                email: '',
                telegram: '',
                whatsapp: '',
                skype: '',
                comment: '',
            },
            address: {
                country: '',
                zipcode: '',
                city: '',
                street: '',
                house: '',
                apartment: '',
            },
            products: []
        },
        catalogFilters: {
            byCategory: false,
        },
        sortBy: 'pricehl',
        sortByOptions: [
            {id: 'avail', name: 'Availability'},
            {id: 'pricelh', name: 'Price (from low to high)'},
            {id: 'pricehl', name: 'Price (from high to low)'},
        ],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items)
                .forEach(key => {
                    state[key] = items[key];
                });
        },
        addProductToCart(state) {
            let orderProducts = state.order.products;
            let productIndexInCart = orderProducts.findIndex(orderProduct => {
                return orderProduct.product_id === state.product.id;
            });
            let hasProductInCart = productIndexInCart >= 0;

            if (hasProductInCart) {
                orderProducts[productIndexInCart].quantity++;
                state.order.products = orderProducts;
            } else {
                state.order.products.push({
                    product_id: state.product.id,
                    quantity: 1,
                    price: state.product.price,
                    image: state.product.images[0],
                    name: state.product.name,
                });
            }
        },
        removeProductFromCart(state, product_id) {
            let productIndexInCart = state.order.products.findIndex(orderProduct => {
                return orderProduct.product_id === state.product.id;
            });
            state.order.products.splice(productIndexInCart, 1);
        },
        clearOrderProducts(state) {
            state.order.products = [];
        },
    },
    actions: {
        getProducts(store) {
            return new Promise((resolve => {
                if (store.state.products.length === 0) {
                    WV.Api.getShopProducts()
                        .then(response => {
                            store.commit('set', ['products', response]);
                            resolve();
                        })
                        .catch(() => {
                            store.commit('set', ['isShopAvailable', false]);
                        });
                } else {
                    resolve();
                }
            }));
        },
        getProduct(store, product_id) {
            return new Promise((resolve => {
                WV.Api.getShopProduct(product_id)
                    .then(response => {
                        store.commit('set', ['productsRelated', response]);
                        store.commit('set', ['product', response[0]]);
                        resolve();
                    });
            }));
        },
        getTaxons(store) {
            return new Promise((resolve => {
                if (store.state.taxons.length === 0) {
                    WV.Api.getShopTaxons()
                        .then(response => {
                            store.commit('set', ['taxons', response]);
                            resolve();
                        })
                        .catch(() => {
                            store.commit('set', ['isShopAvailable', false]);
                        });
                } else {
                    resolve();
                }

            }));
        },
        getOrders(store) {
            return new Promise((resolve => {
                WV.Api.getShopOrders()
                    .then(response => {
                        store.commit('set', ['orders', response]);
                        resolve();
                    });
            }));
        },
        saveOrderToLocalStorage(store, order = false) {
            if (!order) {
                order = store.state.order;
            }
            localStorage.setItem('wvshop_order', JSON.stringify(order));
        },
        loadOrderFromLocalStorage(store) {
            let savedOrderDate = localStorage.getItem('wvshop_order');
            if (savedOrderDate !== null) {
                store.commit('set', ['order', JSON.parse(savedOrderDate)]);
            }
        },
        checkout(store) {
            return new Promise((resolve => {
                WV.Api.postShopCheckout(store.state.order)
                    .then(response => {
                        resolve();
                    });
            }));
        },
    },
    getters: {

    },
};
