<template>
    <nav
        id="webmaster-layout__menu"
        class="webmaster-layout__menu"
        :class="{'webmaster-layout__menu-expanded': isSidebarExpanded}"
    >

        <div
            v-if="isSidebarExpanded"
            @click="closeSidebar"
            class="sidebarCloseOverlay"
        ></div>

        <ul class="menu__container">

            <!-- Menu logo -->
            <li class="menu__header">
                <div class="menu__header_logo">
                    <img src="/images/menu-webvork-logo.svg" alt="Logo"/>
                </div>
                <div class="menu__header_text">
                    <img src="/images/menu-webvork-text.svg" alt="Logo"/>
                </div>
            </li>

            <!-- Menu settings-->
            <li
                v-if="statMini && user"
                class="menu__settings-block">
                <input
                    type="checkbox"
                    id="menu-settings-checkbox"
                    class="menu__settings_checkbox"
                />
                <label
                    id="menu__settings"
                    class="menu__settings"
                    for="menu-settings-checkbox"
                >
                    <span class="menu__settings_arrow">
                        <img
                            src="/images/menu-settings-arrow.svg"
                            alt="Open profile settings"
                        />
                    </span>
                    <div class="menu__settings_avatar">
                        <AvatarLetter
                            :size="40"
                            :words="preparedAvatarWords"
                        ></AvatarLetter>
                        <span
                            v-if="$store.getters['auth/countTicketsUnread']"
                            class="menu__settings_avatar-notification ticket-notificaitions"
                            tabindex="0">
                            {{ $store.getters['auth/countTicketsUnread'] }}
                        </span>
                    </div>
                    <div class="menu__settings_profile-info">
                        <p class="menu__settings_profile-name">
                            {{ user.username }}
                        </p>
                        <p class="menu__settings_profile-id">
                            ID {{ user.id }}
                        </p>
                    </div>
                </label>

                <ul class="menu__settings_list">
                    <li>
                        <div class="menu__settings__languageSwitcher">
                            <FormSelect
                                :label="false"
                                :showResetOption="false"
                                :withIcons="true"
                                v-model:selected="userLanguage"
                                :options="$lng.languages"
                            ></FormSelect>
                        </div>
                    </li>
                    <li>
                        <router-link
                            :to="'/'+this.$route.params.lng+'/webmaster/support'"
                            v-slot="{href, route, navigate}">
                            <a class="menu__settings_item" href="#">
                                <div class="menu__item_logo">
                                    <img src="/images/settings-tickets-logo.svg" alt="Support"/>
                                </div>
                                <p class="menu__item_text">
                                    {{ $lng.__('lk_webmaster_user_menu', 'Tickets') }}
                                </p>
                            </a>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :to="'/'+this.$route.params.lng+'/webmaster/payouts'"
                            v-slot="{href, route, navigate}">
                            <a
                                class="menu__settings_item"
                                :href="href"
                                @click="navigate">
                                <div class="menu__item_logo">
                                    <img src="/images/settings-payouts-logo.svg" alt="Payouts"/>
                                </div>
                                <p class="menu__item_text">
                                {{ $lng.__('lk_webmaster_user_menu', 'Payouts') }}
                            </p>
                            </a>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :to="'/'+this.$route.params.lng+'/webmaster/settings'"
                            v-slot="{href, route, navigate}">
                            <a
                                :href="href"
                                @click="navigate"
                                class="menu__settings_item">
                                <div class="menu__item_logo">
                                    <img src="/images/settings-user-settings-logo.svg" alt="Settings"/>
                                </div>
                                <p class="menu__item_text">
                                {{ $lng.__('lk_webmaster_user_menu', 'User settings') }}
                            </p>
                            </a>
                        </router-link>
                    </li>
                    <li>
                        <a
                            class="menu__settings_item"
                            @click.prevent="$store.dispatch('auth/logout')"
                            href="#">
                            <div class="menu__item_logo">
                                <img src="/images/settings-log-out-logo.svg" alt="Ticket logo"/>
                            </div>
                            <p class="menu__item_text">
                            {{ $lng.__('lk_webmaster_user_menu', 'Log out') }}
                        </p>
                        </a>
                    </li>
                </ul>
            </li>

            <SidebarItem
                v-for="element in elements"
                :element="element"
                :isVisible="element.isVisible"
                :lngNamespace="lngNamespace"
            ></SidebarItem>
      
        </ul>
    </nav>
</template>

<script>

export default {
    name: 'Sidebar',
    props: {
        elements: {
            type: Array,
            default: [],
        },
        lngNamespace: {
            type: String,
        },
    },
    data() {
        return {
            isSidebarExpanded: false,
        };
    },
    computed: {
        statMini() {
            return this.$store.state.webmaster.statMini || false;
        },
        user() {
            return this.$store.state.auth.user;
        },
        userLanguage: {
            get() {
                return this.user.language;
            },
            set(val) {
                this.$store.dispatch('auth/saveUser', {...this.user, language: val})
                    .then(() => {
                        this.$toast.success(this.$lng.__('lk_webmaster_user_settings','Successfully saved'));
                        this.$lng.setLanguage(val);
                    });
            }
        },
        preparedAvatarWords() {
            let words = [];
            let firstName = typeof this.user.first_name !== 'object' && this.user.first_name !== ''
                ? this.user.first_name.replaceAll(' ', '')
                : '';
            let lastName = typeof this.user.last_name !== 'object' && this.user.last_name !== ''
                ? this.user.last_name.replaceAll(' ', '')
                : '';
            firstName + lastName !== ''
                ? words.push(firstName + ' ' + lastName)
                : words.push(this.user.username.replaceAll(' ', ''));
            return words;
        }
    },
    methods: {
        preventBodyScrollIfSidebarActive() {
            const menuContainer = document.getElementsByClassName('menu__container')[0];

            menuContainer.addEventListener('mouseenter', () => {
            });

            menuContainer.addEventListener('mouseleave', () => {
                document.body.classList.remove('ovh');
            });
        },
        openSidebar() {
            this.isSidebarExpanded = true;
            document.body.classList.add('ovh');
        },
        closeSidebar() {
            this.isSidebarExpanded = false;
            document.body.classList.remove('ovh');
        },
        sendYandexMetric(){
            ym(46914753,'reachGoal','tgbotclick');
        }
    },
    mounted() {
        WV.Bus.on('openSidebar', this.openSidebar);
        WV.Bus.on('closeSidebar', this.closeSidebar);
        //this.preventBodyScrollIfSidebarActive();
    }
};
</script>

<style lang="scss">

@import '../scss/utils/mixins.scss';
@import '../scss/utils/variables.scss';

// Миксин раскрывания основного меню
@mixin showMenu() {
    width: 275px;
    background-color: $color-day-lmenu-normal;
    .menu__item_text,
    .menu__header_text {
        opacity: 1;
    }
}

.sidebarCloseOverlay {
    position: absolute;
    background: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: -1000px;
    z-index: $z-index-menu - 300;
}

.webmaster-layout__menu {
    min-width: 70px;
    width: 70px;
    min-height: 100%;
    height: 100%;
    position: fixed;
    background-color: $color-day-lmenu-normal;
    z-index: $z-index-menu;
    @include maxWidth(720) {
        transition: transform 0.3s;
        transform: translateX(-300px);
    }
    &-expanded {
        transition: transform 0.3s;
        transform: translateX(0);
    }
    .menu {
        &__container {
            width: 70px;
            height: 100%;
            background-color: transparent;
            display: flex;
            overflow-y: auto;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-secondary;
            z-index: $z-index-menu - 100;
            transition: background-color 0.2s;
            &::-webkit-scrollbar {
                width: 0;
            }
            @include minWidth(2060) {
                @include showMenu();
            }

            @include maxWidth(720) {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                transition: background-color 0.2s;
                @include showMenu();
            }

            &:hover {
                @include showMenu();
            }
        }

        &__header,
        &__settings {
            display: flex;
            flex-direction: row;
            align-items: center;
            outline: none;
            cursor: pointer;

            &_logo {
                min-width: 70px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: background-color 0.2s;
            }

            &_text {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: $color-day-lmenu-text;
                padding-left: 20px;
                opacity: 0;
                transition: background-color 0.2s, color 0.2s, opacity 0.3s;

                font-family: $font-secondary;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.007em;
            }
        }

        &__header {
            min-height: 100px;
            cursor: default;

            &_logo,
            &_text {
                border-bottom: 1px solid $color-day-black;
            }

            &_isopen {
                @include squareBlock(20px);
                align-self: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 30px;
                margin-top: 3px;
                margin-bottom: 0;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        fill: $color-day-lmenu-text;
                        transition: fill .2s;
                    }
                }

                @include maxWidth(1050) {
                    display: none;
                }

                @include minWidth(2060) {
                    display: none;
                }
            }
        }

        &__settings {
            height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            position: relative;

            &-block {
                @include minWidth(720) {
                    display: none;
                }
            }

            &_checkbox {
                display: none;

                &:checked {
                    ~ .menu__settings_list {
                        display: block;
                    }
                    ~ .menu__settings .menu__settings_arrow img {
                        transform: rotateZ(180deg);
                    }
                }
            }

            &__languageSwitcher{
                padding: 10px 0;
                .selected{
                    color: #fff !important;
                    border: 0 !important;
                    border-radius: 0 !important;
                    background: #2e2d2d !important;
                }
            }

            &_avatar {
                width: 40px;
                height: 40px;
                position: relative;
                z-index: $z-index-avatar;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                &-notification {
                    width: 15px;
                    height: 15px;
                    border: 1px solid $color-day-white;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: $z-index-avatar + 1;

                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                    color: $color-day-white;
                }
            }

            &_profile-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                margin-left: 15px;
            }

            &_profile-name {
                font-weight: 800;
                font-size: 14px;
                line-height: 20px;
                align-items: center;
                color: $color-day-white;
            }

            &_profile-id {
                font-weight: normal;
                font-size: 10px;
                line-height: 20px;
                align-items: center;
                letter-spacing: 0.5px;
                color: $color-day-form-shadow;
            }

            &_arrow {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);

                img {
                    transition: transform 0.2s;
                }
            }

            &_list {
                display: none;
                border-bottom: 1px solid $color-day-black;
                padding-bottom: 10px;
            }

            &_item{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 60px;
                padding-right: 20px;
                padding-left: 20px;
                .menu__item_logo {
                    min-width: 20px;
                }
                .menu__item_text {
                    padding-left: 15px;
                    color: $color-day-white;
                }
            }

            &_item {
                width: 100%;
                background-color: transparent;
                border: none;
                transition: background-color 0.2s;
                &:hover {
                    background-color: $color-day-lmenu-active;
                }
            }

            &_ticket-notificaitions {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                background-color: $color-day-light-blue;
                border-radius: 50%;
                font-size: 14px;
            }
        }
    }

    // Закрепление меню
    &--isopen {
        display: none;

        &:checked {
            ~ .webmaster-layout__menu .menu__container {
                @include minWidth(1050) {
                    @include showMenu();
                }
            }

            ~ .webmaster-layout__menu .menu__header_isopen svg path {
                fill: $color-day-white;
            }

            ~ .webmaster-layout__wrapper {
                width: calc(100% - 285px);
                padding-left: 30px;
                margin-left: 275px;

                @include maxWidth(1050) {
                    width: 100%;
                    margin-left: 0;
                    padding-left: 90px;
                }

                @include maxWidth(720) {
                    padding-left: 20px;
                }

                @include maxWidth(475) {
                    padding-left: 10px;
                }
            }

        }
    }

    // Активный элемент меню
    &--active {
        transform: translateX(0);

        .menu__container {
            @include showMenu();
        }
    }
    .menu__container {
      min-height: 100vh;
      height: calc(100vh + 14px);
      margin-bottom: 10px;
      @include minWidth(2060) {
           height: 100vh;
           margin-bottom: 0;
        }
      &:hover {
        height: 100%;
      }
    }

    .menu__telegram-bot {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 2;
      @include minHeight(1150) {
        bottom: auto;
        top: 100%;
      }
      .menu__item {
        background: #37aee2;
        color: white;
        &:hover {
          background: #1e96c8 !important;
        }
      }
      .menu__item_logo {
        background: #37aee2;
      }
      .menu__item_text {
        background: #37aee2;
        color: white;
      }
    }
}
</style>
