<template>
    <div class="ArabHolidays" v-if="isVisible">

        <div class="ArabHolidays__overlay"></div>

        <div class="ArabHolidays__wrapper">
            <div class="ArabHolidays__header">
                <div class="ArabHolidays__bg"></div>

                <div
                    @click="close()"
                    class="ArabHolidays__close">
                    <svg width="50" height="53" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5833 14.5833L35.4166 35.4166" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                        <path d="M35.4166 14.5833L14.5833 35.4166" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                    </svg>
                </div>

                <div
                    @click="closeAndDontShowAgain()"
                    class="ArabHolidays__discard">
                    {{ $lng.__('lk_webmaster_main', 'Don’t show this message') }}
                </div>
                <div class="ArabHolidays__title">
                    {{ $lng.__('lk_webmaster_main', 'ARABIC HOLIDAYS WITH WEBVŌRK') }}
                </div>
            </div>


            <div class="ArabHolidays__grid">
                <div class="ArabHolidays__content">
                    <div class="ArabHolidays__lead">
                        {{ $lng.__('lk_webmaster_main', 'Take part in the new Webvōrk contest, win the prizes, and take a trip to the Arab Emirates with us!') }}
                    </div>
                    <div class="ArabHolidays__text">
                        {{ $lng.__('lk_webmaster_main', 'To get points within the contest, contact your account manager, and they will grant you access you need') }}
                    </div>
                </div>
                <div class="ArabHolidays__btns">
                    <button
                        @click="clickJoin()"
                        class="wv-btn--transparent ArabHolidays__btn">
                        {{ $lng.__('lk_webmaster_main', 'Participate') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ArabHolidaysModal',
        props: [],
        data() {
            return {
                isVisible: false,
                localStorageItemName: 'AHModal',
                defaultLocalStorageState: {
                    hidePermanent: false,
                    lastShow: false,
                },
                state: {
                    hidePermanent: false,
                    lastShow: false,
                },
            };
        },
        computed: {
            managerTelegramLink() {
                return 'https://t.me/'+WV.store.state.webmaster.manager.telegram;
            },
            hasManagerTelegram() {
                return (typeof WV.store.state.webmaster.manager.telegram) === 'string';
            },
        },
        methods: {
            setState() {
                if (localStorage.getItem(this.localStorageItemName) === null) {
                    localStorage.setItem(this.localStorageItemName, JSON.stringify(this.defaultLocalStorageState));
                    this.state = this.defaultLocalStorageState;
                } else {
                    this.state = JSON.parse(localStorage.getItem(this.localStorageItemName));
                }
            },
            saveStateToLocalStorage() {
                localStorage.setItem(this.localStorageItemName, JSON.stringify(this.state));
            },
            open() {
                this.isVisible = true;
                document.body.scrollIntoView("#app");
            },
            openIfNeed() {
                if (
                    !this.state.hidePermanent
                    && this.state.lastShow !== (new Date).getDate()
                    && this.hasManagerTelegram
                    && !this.$store.state.auth.user.joined_promo
                ) {
                    this.open();
                }
            },
            close() {
                this.isVisible = false;
                this.state.lastShow = (new Date).getDate();
                this.saveStateToLocalStorage();
                ym(46914753,'reachGoal','closepopup');
            },
            closeAndDontShowAgain() {
                this.isVisible = false;
                this.state.hidePermanent = true;
                this.state.lastShow = (new Date).getDate();
                this.saveStateToLocalStorage();
                ym(46914753,'reachGoal','dontshowpopupclick');
            },
            clickJoin() {
                window.open(this.managerTelegramLink, '_blank').focus();
                ym(46914753,'reachGoal','participatepopupclick');
            },
        },
        beforeMount() {
            this.setState();
        },
        mounted() {
            setTimeout(this.openIfNeed, 5000);
        },
    };
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';
    $color-promo: #FE682D;


    .ArabHolidays{
        position: absolute;
        top: 0;
        right:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex:none;
        &__overlay{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            background: rgba(0, 0, 0, 0.7);
            position: fixed;
            z-index: 5001;
            bottom: 0;
            left: 0;
            right: 0;
            top: -70px;
            padding-bottom: 70px;
            transform: translateY(70px);
            outline: 0;
        }
        &__wrapper{
            position: relative;
            overflow: visible;
            background: #fff;
            min-height: 200px;
            max-height: 100vh;
            height: auto;
            width: 320px;
            max-width: 100%;
            z-index: 9999;
            box-shadow: 0 50px 100px rgba(0, 0, 0, 0.25);
            -webkit-overflow-scrolling: touch;
            font-family: $font-secondary;
            font-size: 16px;
            color: $color-day-dark-gray;
            letter-spacing: 0;
            margin-top: 100px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            @extend .noScrollbar;
            @include break(sm) {
                width: 400px;
            }
            @include break(md) {
                width: 600px;
                margin-top: 200px;
            }
            @include break(xl) {
                width: 1100px;
            }
        }
        &__header{
            position: relative;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            margin-top: -40px;
            padding: 20px 40px 40px;
            background: url('https://minio.cdnwv.com/lk.webvork/arabian_holidays/arabian_holidays_modal_clouds_v2.jpg') top center no-repeat;
            background-size: cover;
            @include break(md){
                padding: 20px 70px 40px;
            }
        }
        &__bg{
            background: url('https://minio.cdnwv.com/lk.webvork/arabian_holidays/arabian_holidays_modal_bg_v3.png') top center no-repeat;
            background-size: auto 100%;
            position: absolute;
            z-index: 1;
            overflow: hidden;
            top: -20px;
            left: 0;
            right: 0;
            bottom: 0;
            width: 320px;
            height: calc(100% + 20px);
            @include break(sm) {
                width: 400px;
            }
            @include break(md) {
                width: 600px;
            }
            @include break(xl) {
                width: 1100px;
                height: calc(100% + 90px);
                top: -90px;
            }
        }
        &__grid{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 0 40px 0;
            @include break(md) {
                padding: 0 70px 0;
            }
            @include break(xl) {
                flex-direction: row;
                padding: 0 70px 60px;
            }
        }
        &__content{
            width: 100%;
            margin-bottom: 40px;
            @include break(xl) {
                width: 60%;
                margin-bottom: 0;
            }
        }
        &__close{
            display: block;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 3;
        }
        &__discard{
            display: inline-block;
            color: #7A7A7A;
            font-family: $font-primary;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 30px;
            position: relative;
            z-index: 3;
            cursor: pointer;
            @include break(sm){
                font-size: 14px;
            }
            @include break(xl) {
                font-size: 16px;
                margin-bottom: 50px;
            }
            &:hover{
                text-decoration: underline;
            }
        }
        &__title{
            position: relative;
            z-index: 3;
            width: 40%;
            font-size: 26px;
            line-height: 26px;
            text-transform: uppercase;
            font-weight: 900;
            font-family: $font-primary;
            color: $color-promo;
            @include break(sm) {
                font-size: 36px;
                line-height: 36px;
            }
            @include break(md) {
                font-size: 40px;
                line-height: 40px;
            }
            @include break(xl) {
                font-size: 72px;
                line-height: 72px;
            }
        }
        &__lead{
            font-family: $font-primary;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            color: #000;
            padding-bottom: 20px;
            @include break(sm){
                font-size: 24px;
                line-height: 30px;
            }
        }
        &__text{
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        &__btns{
            width: calc(100% + 80px);
            height: 66px;
            @include break(md) {
                width: calc(100% + 140px);
            }
            @include break(xl) {
                width: auto;
            }
        }
        &__btn{
            display: block;
            background: $color-promo;
            border-radius: 0 0 30px 30px;
            height: 66px;
            flex: none;
            width: 100%;
            color: #fff;
            font-weight: 700;
            font-size: 22px;
            &:hover{
                background: darken($color-promo, 10%);
            }
            @include break(xl) {
                border-radius: 15px;
                width: 300px;
            }
        }
    }
</style>
