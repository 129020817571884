<template>
        <Modal
            :title="false"
            :width="700"
            v-if="isModalVisible"
            @close="close()">
            <div class="SocialsModal">

                <div class="SocialsModal__header">

                    <div class="SocialsModal__header__bg">
                        <img src="https://minio.cdnwv.com/lk.webvork/images/social-widget-webvork-logo-bg.svg" alt="">
                    </div>

                    <div class="SocialsModal__logo">
                        <div class="SocialsModal__logo__icon">
                            <img src="https://minio.cdnwv.com/lk.webvork/images/webvork-logo-icon-green.svg" alt="Logo"/>
                        </div>
                        <div class="SocialsModal__logo__text">
                            <img src="https://minio.cdnwv.com/lk.webvork/images/webvork-logo-text-black.svg" alt="Logo"/>
                        </div>
                    </div>
                    <div class="SocialsModal__title">Подпишись на наши социальные сети</div>
                    <div class="SocialsModal__lead">Будь в курсе наших последних новостей</div>
                </div>

                <div class="SocialsModal__content">
                    <div class="SocialsModal__vk" @click="clickVk()">
                        <div id="vk_groups"></div>
                    </div>
                    <div class="SocialsModal__icons">
                        <a
                            @click="clickFb()"
                            target="_blank"
                            href="https://www.facebook.com/webvorkofficial/"
                            class="SocialsModal__icon">
                            <img src="https://minio.cdnwv.com/lk.webvork/icons/footer-facebook-icon.svg" alt="Facebook logo"/>
                        </a>
                        <a
                            @click="clickInstagram()"
                            target="_blank"
                            href="https://www.instagram.com/webvork/"
                            class="SocialsModal__icon">
                            <img
                                src="https://minio.cdnwv.com/lk.webvork/icons/footer-instagram-icon.svg"
                                alt="Facebook logo"
                            />
                        </a>
                        <a
                            @click="clickTelegram()"
                            target="_blank"
                            href="https://t.me/webvorkofficial"
                            class="SocialsModal__icon">
                            <img src="https://minio.cdnwv.com/lk.webvork/icons/footer-telegram-icon.svg" alt="Telegram logo"/>
                        </a>
                    </div>
                </div>

            </div>
        </Modal>
</template>

<script>
export default {
    name: 'SocialsModal',
    props: [],
    data() {
        return {
            isModalVisible: false,
            lastShowDate: false,
            localStorageItemName: 'socialModalLastShow',
        };
    },
    computed: {},
    methods: {
        clickVk() {
            ym(46914753,'reachGoal','popupvk');
        },
        clickInstagram() {
            ym(46914753,'reachGoal','popupinstagram')
        },
        clickFb() {
            ym(46914753,'reachGoal','popupfacebook');
        },
        clickTelegram() {
            ym(46914753,'reachGoal','popuptelegram')
        },
        close() {
            this.isModalVisible = false;
        },
        appendVkOpenApiScript() {
            let head = document.getElementsByTagName('head')[0];
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.onload = () => {
                this.initVkWidjet();
            }
            script.src = 'https://vk.com/js/api/openapi.js?169';
            head.appendChild(script);
        },
        initVkWidjet() {
            if (this.isModalVisible) {
                setTimeout(() => {
                    if (document.getElementById('vk_groups') !== null) {
                        document.getElementById('vk_groups').innerHTML = '';
                    }
                    let vk_width = document.querySelector('.SocialsModal__vk').clientWidth;
                    VK.Widgets.Group("vk_groups", {mode: 3, no_cover: 1, width: vk_width, height: "300", color3: '00DCA2'}, 153500401);
                }, 1000)
            }
        },
        setState() {
            if (localStorage.getItem(this.localStorageItemName) !== null) {
                this.lastShowDate = Date.parse(localStorage.getItem(this.localStorageItemName));
            }
        },
        showModal() {
            localStorage.setItem(this.localStorageItemName, (new Date).getFormattedDate());
            this.isModalVisible = true;
            this.initVkWidjet();
        },
        showModalIfNeed() {
            let needChangeToShow = 1209600000; // 14 days in millisecond
            if (WV.Lng.lng_code === 'ru') {
                if (!this.lastShowDate || (Date.now() - new Date(this.lastShowDate)) > needChangeToShow) {
                    this.showModal();
                }
            }
        },
    },
    beforeMount() {
        this.setState();
        WV.Bus.on('resize', this.initVkWidjet);
    },
    mounted() {
        this.appendVkOpenApiScript();
        setTimeout(this.showModalIfNeed, 5000);

    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.SocialsModal{
    font-family: $font-primary;
    margin: -20px;
    background: $color-day-bg;
    &__header{
        padding: 40px 30px 20px;
        background: $color-day-table;
        position: relative;
        @include break(md) {
            padding: 40px 60px 20px;
        }
        &__bg{
            position: absolute;
            top: -210px;
            right: -180px;
            z-index: 1;
            @include break(sm) {
                top: -210px;
                right: -50px;
            }
        }
    }
    &__logo{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;
        position: relative;
        z-index: 3;
        &__icon{
            height: 30px;
            margin-right: 10px;
        }
        &__text{
            height: 15px;
        }
    }
    &__title{
        font-size: 32px;
        line-height: 36px;
        font-weight: 800;
        position: relative;
        z-index: 3;
    }
    &__lead{
        font-size: 16px;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 40px;
        position: relative;
        z-index: 3;
    }
    &__content{
        background: $color-day-bg;
        padding: 30px 30px;
        display: flex;
        flex-direction: column;
        @include break(sm) {
            flex-direction: row;
        }
        @include break(md) {
            padding: 30px 60px;
        }
    }
    &__vk{
        width: 100%;
        @include break(sm){
            max-height: 205px;
        }
        @include break(md) {
            max-width: calc(100% - 81px);
        }
        .wcommunity_wrap:before{
            box-shadow: none !important;
        }
    }
    &__icons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        @include break(sm) {
            padding-left: 16px;
            flex-direction: column;
            justify-content: stretch;
            margin-top: 0;
        }
    }
    &__icon{
        display: flex;
        border-radius: 15px;
        align-items: center;
        margin-bottom: 6px;
        width: calc(33.333% - 10px);
        height: 65px;
        background: white;
        justify-content: center;
        @include break(sm) {
            height: calc(33.333% - 4px);
            width: 65px;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

</style>
