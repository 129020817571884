export default {
    namespaced: true,

    state: () => ({
        token: false,
        login: '',
        password: '',
        role: false,
        user: false,
        isUserLoaded: false,
    }),
    mutations: {
        setLoginAndPassword(state, {login, password}) {
            state.login = login;
            state.password = password;
        },
        setToken(state, token) {
            state.token = token;
        },
        setRole(state, role) {
            state.role = role;
        },
        setUser(state, user) {
            state.user = user;
            state.isUserLoaded = true;
        },
    },
    actions: {
        login(store) {
            return new Promise((resolve, reject) => {
                WV.Api.postLogin(store.state.login, store.state.password)
                    .then(response => {
                        store.commit('setToken', response.token);
                        store.commit('setRole', response.role);
                        localStorage.setItem('token', response.token);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        register(store, payload) {
            return new Promise((resolve, reject) => {
                WV.Api.postRegister(payload)
                    .then(response => {
                        resolve();
                    })
                    .catch(error => {
                        if (error.response) {
                            reject(error.response.data.message);
                        } else {
                            reject(error.message);
                        }
                    });
            });
        },
        logout(store) {
            store.commit('setToken', false);
            localStorage.removeItem('token');
            document.location.reload();
        },
        getUser(store) {
            return new Promise((resolve => {
                WV.Api.getUser()
                    .then(user => {
                        store.commit('setUser', user);
                        WV.Lng.setLanguageFromUserProfile(user.language);
                        resolve();
                    });
            }));
        },
        saveUser(store, user) {
            return new Promise((resolve => {
                WV.Api.updateUser(user)
                    .then(user => {
                        store.commit('setUser', user);
                        resolve(user);
                    });
            }));
        },
        sendRecoveryPasswordEmail(store, email) {
            return new Promise((resolve => {
                WV.Api.postRecoveryPasswordSendEmail(email)
                    .then(() => {
                        resolve();
                    });
            }));
        },
        setNewPassword(store, {token, password, email}) {
            return new Promise((resolve => {
                WV.Api.postRecoveryPasswordSetNewPassword(token, password, email)
                    .then(() => {
                        resolve();
                    });
            }));
        }
    },
    getters: {
        hasToken(state) {
            return state.token !== false;
        },
        getToken(state, getters) {
            if (getters.hasToken) {
                return state.token;
            }
            if (localStorage.getItem('token')) {
                return localStorage.getItem('token');
            }
        },
        countTicketsUnread(state) {
            return state.user ? state.user.tickets_unread.length : 0;
        },
        isUserApproved(state) {
            return state.user ? state.user.is_approved : false;
        },
        isUserLoaded(state) {
            return state.isUserLoaded;
        },
        isUserPollPassed(state) {
            return state.user ? state.user.poll_passed : false;
        },
        isRoleWebmaster(state) {
            return state.role === 'webmaster';
        },
        isRoleManager(state) {
            return state.role === 'manager';
        },
        isRoleOperator(state) {
            return state.role === 'operator';
        },
    }
};