import { MobileHeader } from './Menu'
import { MoveItem } from "./MoveItem"

// КЛАСС ОБЩИЙ Popup
export class Popup {
    constructor (name = null) {
        this.name = name
        this.bg = document.createElement('div')
        this.bg.classList.add('wv-popup--bg')
        this.popup = document.getElementById(name)
        this.bg.classList.add(`${this.name}__bg`)
    }

    showPopup(position = null) {
        if (!position) {
            window.scrollTo(0, 0)
        } else {
            this.bg.style.position = position
        }

        this.popup.classList.add(`wv-popup--active`)
        this.bg.appendChild(this.popup)

        const bg = this.bg.cloneNode(true)
        const bodyElement = document.body
        bodyElement.appendChild(bg)

        bg.addEventListener('click', event => {
            this.hidePopup(event.target)
        })

        const closeButton = document.querySelector('.wv-popup--bg-close')

        if (closeButton) {
            const popupBgHeader = new MobileHeader('wv-popup--bg-header')
            popupBgHeader.init()

            closeButton.addEventListener('click', event => {
                this.hidePopup(event.currentTarget.parentElement.parentElement)
            })
        }
    }

    addCloseButton() {
        const closeButton = document.createElement('div')
        const bgHeader = document.createElement('div')

        bgHeader.classList.add('wv-popup--bg-header')
        bgHeader.id = 'wv-popup--bg-header'
        closeButton.classList.add('wv-popup--bg-close')

        closeButton.innerHTML = `
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6585 14.9985L29.6572 2.00039C30.1143 1.54349 30.1143 0.799571 29.6572 0.342673C29.2 -0.114224 28.4558 -0.114224 27.9986 0.342673L15 13.3408L1.99551 0.348531C1.53839 -0.114224 0.799961 -0.114224 0.34284 0.348531C-0.11428 0.811286 -0.11428 1.54935 0.34284 2.00625L13.3415 14.9985L0.34284 27.9967C-0.11428 28.4536 -0.11428 29.1975 0.34284 29.6544C0.571401 29.8828 0.870287 30 1.16917 30C1.46806 30 1.76695 29.8828 1.99551 29.6544L15 16.6563L28.0045 29.6544C28.2331 29.8828 28.5319 30 28.8308 30C29.1297 30 29.4286 29.8828 29.6572 29.6544C30.1143 29.1975 30.1143 28.4536 29.6572 27.9967L16.6585 14.9985Z"
          fill="none"
        />
      </svg>`

        bgHeader.appendChild(closeButton)
        this.bg.appendChild(bgHeader)
    }

    hidePopup(element) {
        if (!element.classList.contains('wv-popup--bg')) return
        const bodyElement = document.body
        bodyElement.removeChild(element)
    }

    init() {
        if (!this.popup) return
        this.addEventButton()
    }
}

// Setting popup
export class SettingPopup extends Popup {
    constructor (name) {
        super(name)
        this.button = document.getElementById('wv-popup--button')
    }

    addClasses() {
        this.popup.classList.add(`${this.name}--active`)
        this.popup.classList.add('wv-popup--active')
        this.button.classList.add('wv-popup--active')
    }

    removeClasses() {
        this.popup.classList.remove(`${this.name}--active`)
        this.popup.classList.remove('wv-popup--active')
        this.button.classList.remove('wv-popup--active')
    }

    showPopup() {
        this.addClasses()

        const bg = this.bg.cloneNode(true)
        document.body.appendChild(bg)

        bg.addEventListener('click', event => {
            this.hidePopup()
        })
    }

    addEventButton() {
        if (!this.button) return

        this.button.addEventListener('click', () => {
            this.showPopup()
        })
    }

    hidePopup() {
        let bg = document.querySelector('.wv-popup--bg.wv-settings-popup__bg')
        if (bg !== null) bg.remove()
        this.removeClasses()
    }

    init() {
        if (!this.popup) return
        this.addEventButton()
    }
}

// News popup
export class PopupNews extends Popup {
    constructor (name) {
        super(name)
        this.news = [ ...document.querySelectorAll(`.${this.name}--button`) ]
        this.isOpen = false
    }

    addEventNews() {
        if (!this.news.length) return

        this.news.forEach(el => {
            el.addEventListener('click', event => {
                this.targetNews = event.currentTarget
                const newsData = this.getNewsData(this.targetNews.dataset.newsId)

                newsData
                    .then(result => {
                        if (result.statusText === 'OK') {
                            this.showPopup()
                            this.setDataNewsPopup(result.data)
                            this.createNextPrevButton()
                        }
                    })
                    .catch(err => console.error(err))

            })
        })
    }

    showPopup() {
        window.scrollTo(0, 0)

        this.popup.classList.add(`wv-popup--active`)
        this.bg.appendChild(this.popup)

        const bg = this.bg.cloneNode(true)
        const bodyElement = document.body
        bodyElement.appendChild(bg)
        const closeButton = document.querySelector('.wv-popup--bg-close')

        // bg.addEventListener('click', event => {
        //     this.hidePopup(event.target)
        //     this.isOpen = false
        // })

        if (closeButton) {
            const popupBgHeader = new MobileHeader('wv-popup--bg-header')
            popupBgHeader.init()

            closeButton.addEventListener('click', event => {
                this.hidePopup(event.currentTarget.parentElement.parentElement)
                this.isOpen = false
            })
        }
    }

    setDataNewsPopup(newsData) {
        if (!newsData) return

        if (!this.isOpen) {
            this.image = document.getElementById(`${this.name}--image`)
            this.title = document.getElementById(`${this.name}--title`)
            this.date = document.getElementById(`${this.name}--date`)
            this.content = document.getElementById(`${this.name}--content`)
            this.isOpen = true
        }

        if (this.image) {
            this.image.src = `/upload/news_logo/${newsData.image}`
        }
        if (this.title) {
            this.title.innerHTML = newsData.title || ''
        }
        if (this.date) {
            this.date.innerHTML = this.getCreateDate(newsData.date)
        }
        if (this.content) {
            this.content.innerHTML = newsData[ 'full_description' ] || ''
        }
    }

    getCreateDate(date) {
        if (!date) return ''

        const newDate = new Date(date)
        const day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
        const month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1
        const year = newDate.getFullYear()

        return `${day}. ${month}. ${year}`
    }

    getNewsData(newsId) {
        return axios.get(`/site/news?id=${newsId}`)
    }

    createNextPrevButton() {
        const header = document.querySelector('.wv-popup--bg-header')
        this.prevButton = document.createElement('button')
        this.nextButton = document.createElement('button')
        const prevIcon = document.createElement('div')
        const nextIcon = document.createElement('div')

        this.prevButton.type = 'button'
        this.nextButton.type = 'button'
        this.prevButton.classList.add(`${this.name}__prev`)
        this.nextButton.classList.add(`${this.name}__next`)

        prevIcon.classList.add(`${this.name}__prev_icon`)
        nextIcon.classList.add(`${this.name}__next_icon`)

        prevIcon.innerHTML = `
    <svg width="24" height="72" viewBox="0 0 24 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2939 1L1.70571 36L22.2939 71" stroke="none" stroke-width="2" stroke-linecap="round"/>
    </svg>`

        nextIcon.innerHTML = `
      <svg width="24" height="72" viewBox="0 0 24 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.70605 1L22.2943 36L1.70605 71" stroke="none" stroke-width="2" stroke-linecap="round"/>
      </svg>`

        this.prevButton.appendChild(prevIcon)
        this.nextButton.appendChild(nextIcon)

        this.navButtonUpdate()

        this.prevButton.addEventListener('click', () => {
            this.newsUpdate(this.targetNews.previousSibling)
        })

        this.nextButton.addEventListener('click', () => {
            this.newsUpdate(this.targetNews.nextSibling)
        })

        if (header) {
            header.appendChild(this.prevButton)
            header.appendChild(this.nextButton)
        }
    }

    navButtonUpdate() {
        this.nextButton.removeAttribute('style')
        this.prevButton.removeAttribute('style')

        if (this.targetNews.previousSibling.nodeName === '#text') {
            this.prevButton.style.visibility = 'hidden'
        }

        if (this.targetNews.nextSibling.nodeName === '#text') {
            this.nextButton.style.visibility = 'hidden'
        }
    }

    newsUpdate(newsTarget) {
        this.targetNews = newsTarget

        const disabled = () => {
            this.prevButton.disabled = !this.prevButton.disabled
            this.nextButton.disabled = !this.nextButton.disabled
        }

        this.navButtonUpdate()
        disabled()

        this.getNewsData(newsTarget.dataset.newsId)
            .then(result => {
                this.setDataNewsPopup(result.data)
                disabled()
            })
            .catch(err => console.error(err))
    }

    init() {
        if (!this.popup) return
        this.addCloseButton()
        this.addEventNews()
    }
}

// New ticket popup
export class NewTicket extends Popup {
    constructor (name) {
        super(name)
        this.button = [ ...document.querySelectorAll(`.${this.name}--button`) ]
    }

    addEventButton() {
        if (!this.button.length) return

        this.button.forEach(button => {
            button.addEventListener('click', () => {
                this.showPopup('fixed')

                const cancelButton = document.getElementById(`${this.name}--close`)

                if (cancelButton) {
                    cancelButton.addEventListener('click', event => {
                        const bg = document.querySelector('.wv-popup--bg')
                        if (bg) {
                            this.hidePopup(bg)
                        }
                    })
                }
            })
        })

    }

    init() {
        if (!this.popup) return
        this.addCloseButton()
        this.addEventButton()
    }
}

// Costomize column popup
export class PopupCostomizeColum extends NewTicket {
    constructor (name) {
        super(name)
        this.button = document.getElementById(`${this.name}--button`)
    }

    addEventButton() {
        if (!this.button) return

        this.button.addEventListener('click', () => {
            this.createListColumns()
            this.showPopup()
            const moveItem = new MoveItem()
            moveItem.init()
        })
    }

    createListColumns() {
        const content = this.popup.querySelector('.wv-costomize-columns-popup__content')

        if (!content) return
        content.innerHTML = ''

        const columns = this.getColumnElement()
        const item = document.createElement('div')
        item.classList.add('wv-costomize-columns-popup__item')
        let cloneItem, checkbox

        columns.forEach((column, index) => {
            cloneItem = item.cloneNode(false)

            checkbox = `
          <div class="layout-checkbox">
            <input
              class="wv-checkbox__tgl--green"
              name="${column.dataset.value}"
              id="cbx-${index}"
              type="checkbox"
              checked
            />
            <label
              class="wv-checkbox__tgl-wv-btn--green"
              for="cbx-${index}"
            ></label>
            <p class="layout-checkbox_name">${column.textContent}</p>
          </div>
          <div class="wv-costomize-columns-popup__move wv-move-item--button">
            <img src="/new/images/move-item-icon.svg" alt="Move icon" />
          </div>`

            cloneItem.innerHTML = checkbox

            content.appendChild(cloneItem)
        })

    }

    getColumnElement() {
        const columns = [ ...this.button.parentElement.parentElement.querySelectorAll('th span') ]

        if (columns.length) {
            return columns
        }
    }
}

// Webvorkshop popup
export class NewWebvorkshop extends Popup {
    constructor (name) {
        super(name)
        this.webvorkshopItem = [ ...document.querySelectorAll(`.${this.name}--item`) ]
    }

    addEventButton() {
        if (!this.webvorkshopItem.length) return

        this.webvorkshopItem.forEach(item => {
            const button = item.querySelector(`.${this.name}--button`)

            if (button) {
                button.addEventListener('click', event => {
                    event.preventDefault()
                    this.showPopup()

                    let popupParams = document.getElementById(this.name)
                    popupParams = popupParams.getBoundingClientRect()
                })
            }
        })
    }

    init() {
        if (!this.popup) return
        this.addEventButton()
        this.addCloseButton()
    }
}

// Setting payment system popup
export class SettingPaymentSystem extends Popup {
    constructor (name) {
        super(name)
        this.openButton = document.getElementById(`${this.name}--button`)
    }

    addEventButton() {
        if (this.openButton) {
            this.openButton.addEventListener('click', () => {
                this.showPopup()
                this.getTabs()

                const closeButton = document.getElementById(`${this.name}--close`)

                if (closeButton) {
                    closeButton.addEventListener('click', event => {
                        this.hidePopup(event.currentTarget.parentElement.parentElement.parentElement.parentElement)
                    })
                }
            })
        }
    }

    getTabs() {
        this.tabs = document.querySelectorAll(`.${this.name}--tab`)

        if (this.tabs.length) {
            this.tabs.forEach(tab => {
                tab.addEventListener('click', event => {
                    this.checkTabs(event.target)
                })
            })
        }
    }

    checkTabs(targetElement) {
        this.tabs.forEach(tab => {
            const {tabName} = tab.dataset
            const tabContent = document.getElementById(tabName)

            if (tab == targetElement && tabContent) {                
                tab.classList.add(`${this.name}--active`)
                tabContent.classList.add(`${this.name}--tab-active`)
                return
            }

            if (tabContent) {
                tab.classList.remove(`${this.name}--active`)
                tabContent.classList.remove(`${this.name}--tab-active`)
            }
        })
    }

    init() {
        if (!this.popup) return
        this.addEventButton()
        this.addCloseButton()
    }
}

// Delete popup
export class DeletePopup extends Popup {
    constructor (name) {
        super(name)
        this.buttons = [ ...document.querySelectorAll(`.${this.name}--button`) ]
    }

    addEventButton() {
        this.buttons.forEach(button => {
            button.addEventListener('click', event => {
                this.showPopup('fixed')

                const targetDataset = event.currentTarget.dataset

                const deleteButton = document.getElementById(`${this.name}--delete`)
                const cancelButton = document.getElementById(`${this.name}--close`)
                const title = document.getElementById(`${this.name}--title`)

                if (deleteButton) {
                    deleteButton.href = targetDataset.deleteUrl
                }

                if (title) {
                    title.textContent =
                    `${title.textContent.replace('?', '')} "${targetDataset.deleteName}" ?`
                }

                if (cancelButton) {
                    cancelButton.addEventListener('click', () => {
                        const bg = document.querySelector('.wv-popup--bg')
                        if (bg) {
                            this.hidePopup(bg)
                        }
                    })
                }
            })
        })
    }


    init() {
        if (!this.buttons.length) return

        this.addEventButton()
    }
}
