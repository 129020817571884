<template>
    <div
        v-if="countOrderProducts > 0 && isShopAvailable"
        @click="goToCheckout()"
        class="ShopCart">

        <SvgUse :width="22" :height="22" id="cart"></SvgUse>

        <div class="ShopCart__label">
            {{ countOrderProducts }}
        </div>

    </div>
</template>

<script>
export default {
    name: 'ShopCart',
    props: [],
    data() {
        return {};
    },
    computed: {
        order() {
            return this.$store.state.shop.order;
        },
        countOrderProducts() {
            return collect(this.order.products).sum('quantity');
        },
        isShopAvailable() {
            return this.$store.state.shop.isShopAvailable;
        },
    },
    methods: {
        goToCheckout() {
            this.$router.push('/'+this.$route.params.lng+'/webmaster/shop/checkout');
        },
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.ShopCart{
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: white;
    display: flex;
    flex:none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
    margin: 0 30px 0 0;
    position: relative;
    &__label{
        position: absolute;
        top: -5px;
        right: -5px;
        font-weight: 500;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        background: #5493A4;
        color: white;
        border-radius: 50%;
    }
}

</style>
