import Vuex from 'vuex';
import { createLogger } from 'vuex'

import auth from './moduleAuth.js'
import shop from './moduleShop.js'
import webmaster from './moduleWebmaster.js'

export default new Vuex.Store({
    state: {
        user_role: 'webmaster',
        isMobile: window.innerWidth < 1000,
    },
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1]
        },
    },
    actions: {
        checkIsMobile(store) {
            console.log('checkIsMobile');
            store.commit('set', ['isMobile', window.innerWidth < 1000])
        },
    },
    modules: {
        auth,
        shop,
        webmaster,
    },
    plugins: process.env.NODE_ENV === 'production' ? [] : [
        createLogger()
    ]
})
