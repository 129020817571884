import {createApp} from 'vue';
import Webmaster from '@/views/Webmaster/Webmaster';

const Lng = {
    name: 'Lng',
    data() {
        return {
            config: {
                headers: {Authorization: `Bearer ${WV.store.getters['auth/getToken']}`}
            },
            lng_code: 'en',
            modules: {},
            languages: [
                {
                    shortname: 'English',
                    name: 'English (UK)',
                    icon: 'geoEN',
                    iso: 'en',
                    id: 'en',
                },
                {
                    shortname: 'Русский',
                    name: 'Русский (RU)',
                    icon: 'geoRU',
                    iso: 'ru',
                    id: 'ru',
                },
                {
                    shortname: 'Deutsch',
                    name: 'Deutsch (DE)',
                    icon: 'geoDE',
                    iso: 'de',
                    id: 'de',
                },
                {
                    shortname: 'Spain',
                    name: 'Español (ES)',
                    icon: 'geoES',
                    iso: 'es',
                    id: 'es',
                },
                {
                    shortname: 'Italian',
                    name: 'Italiano (IT)',
                    icon: 'geoIT',
                    iso: 'it',
                    id: 'it',
                },
            ],
        };
    },
    computed: {
        currentDomainZone() {
            return document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];
        },
        baseUrl() {
            this.NEED_USE_TEST_DOMAIN = parseInt(process.env.VUE_APP_NEED_USE_TEST_DOMAIN) === 1;

            if (this.NEED_USE_TEST_DOMAIN) {
                return 'https://webvork.test/api/v1/'
            } else {
                return process.env.NODE_ENV === 'production'
                    ? 'https://webvork.' + this.currentDomainZone + '/api/language/'
                    : 'http://webvork.' + this.currentDomainZone + '/api/language/';
            }
        },
        getLngCodeFromUrl() {
            return document.location.pathname.split('/')[1];
        },
    },
    methods: {
        /**
         * GET request
         * @method get
         * @param {string} endpoint - URL
         */
        get(endpoint) {
            return new Promise((resolve, reject) => {
                axios.get(this.baseUrl + endpoint, this.config)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(response => {
                        reject(response);
                    });
            });
        },

        loadTranslationModule(namespace) {
            return new Promise((resolve, reject) => {
                this.get(`dictionary/${namespace}/${this.lng_code}`)
                    .then(response => {
                        this.modules[namespace] = {
                            terms: response.term,
                            version: response.version,
                            code: this.lng_code
                        };
                        this.saveModulesToLocalStorage();
                        resolve();
                    });
            });
        },

        getTerm(namespace, string) {
            let term = string;
            if (typeof this.modules[namespace] === 'undefined') {
                //console.log(`TRANS: (${this.lng_code}) ${namespace} - "${term}"`);
            } else {
                let item = collect(this.modules[namespace].terms)
                    .where('id', term)
                    .first();
                if (item) term = item.value
                //else console.log(`TRANS: (${this.lng_code}) ${namespace} - "${term}"`);
            }
            return term;
        },

        __(namespace, string) {
            return this.getTerm(namespace, string);
        },

        saveModulesToLocalStorage() {
            localStorage.setItem('lng_modules', JSON.stringify(this.modules));
        },
        loadModulesFromLocalStorage() {
            this.modules = JSON.parse(localStorage.getItem('lng_modules'));
        },

        isLngCodeValid(lng_code) {
            return ['ru', 'en', 'de', 'es', 'it'].indexOf(lng_code) !== -1;
        },

        getIsoCodeByCountry(country) {
            let language = collect(this.languages)
                .where('id', country)
                .first();
            return (typeof language !== 'undefined') ? language.iso : 'en';
        },
        getCountryNameByIso(iso) {
            return collect(this.languages)
                .where('iso', iso)
                .first().id;
        },

        setLanguage(lng_code) {
            WV.router.push({ name: WV.router.currentRoute.value.name, params:{lng:lng_code} }).then(() => {
                WV.router.go(0)
            })
        },

        reloadAllMoules() {
            this.loadTranslationModule('lk_webmaster_main');
            this.loadTranslationModule('lk_webmaster_main_menu');
            this.loadTranslationModule('lk_webmaster_header');
            this.loadTranslationModule('lk_webmaster_footer');
            this.loadTranslationModule('lk_webmaster_dashboard');
            this.loadTranslationModule('lk_webmaster_offers');
            this.loadTranslationModule('lk_webmaster_streams_crud');
            this.loadTranslationModule('lk_webmaster_sources');
            this.loadTranslationModule('lk_webmaster_errors');
            this.loadTranslationModule('lk_webmaster_user_menu');
            this.loadTranslationModule('lk_webmaster_user_settings');
            this.loadTranslationModule('lk_webmaster_streams');
            this.loadTranslationModule('lk_webmaster_countries');
            this.loadTranslationModule('lk_webmaster_domains');
            this.loadTranslationModule('lk_webmaster_payouts');
            this.loadTranslationModule('lk_webmaster_webvorkshop');
        },

        setLngCodeFromUrlToData() {
            this.lng_code = this.isLngCodeValid(this.getLngCodeFromUrl) ? this.getLngCodeFromUrl : 'en';
        },

        setLanguageFromUserProfile(profile_lng_code) {
            if (this.getLngCodeFromUrl !== profile_lng_code) {
                if (WV.is404Route()) {
                    document.location.pathname = '/'+profile_lng_code+document.location.pathname
                } else {
                    document.location.pathname = document.location.pathname.replace(`/${this.getLngCodeFromUrl}/`, `/${profile_lng_code}/`)
                }
            }

        },

    },

    beforeMount() {
        this.setLngCodeFromUrlToData()
    },

    mounted() {
        this.reloadAllMoules()
    },

};

export default createApp(Lng);
