export class BaseApi {

    /**
     * @constructor
     */
    constructor() {
        this.baseUrl = process.env.NODE_ENV === "production"
            ? 'https://webvork.ru/api/language/'
            : 'http://webvork.test/api/language/';
        this.config = {}
    }

    /**
     * GET request
     * @method get
     * @param {string} endpoint - URL
     */
    get(endpoint) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + endpoint, this.config).then(response => {
                resolve(response.data)
            }).catch(response => {
                reject(response)
            });
        })
    }

}


export default class TranslationApi extends BaseApi {
    constructor() { super() }

    updateStorage() {}

    checkVersion() {}

    setLanguage(namespace, language) {
        localStorage.setItem('lng_namespace', namespace)
        localStorage.setItem('lng_code', language)
        return this.getLanguage()
    }

    getVersion() {
        return this.get(`version/${localStorage.getItem('lng_namespace')}/${localStorage.getItem('lng_code')}`)
    }

    isVersionActual() {
        return new Promise((resolve, reject) => {

            if (!this.isLocalStorageStateValid()) resolve(false)

            let currentVersion = localStorage.getItem('lng_version')

            if (currentVersion === null) resolve(false)

            this.getVersion().then(actualVersion => {
                if (actualVersion === currentVersion) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
    }

    getLanguage() {
        return new Promise((resolve, reject) => {
            this.get(`dictionary/${localStorage.getItem('lng_namespace')}/${localStorage.getItem('lng_code')}`)
                .then(response => {
                    this.putTermsToLocalStorage(response.term)
                    localStorage.setItem('lng_version', response.version)
                    resolve()
                })
        })
    }

    isLocalStorageStateValid() {
        return localStorage.hasOwnProperty('lng_version')
            && localStorage.hasOwnProperty('lng_namespace')
            && localStorage.hasOwnProperty('lng_code')
            && localStorage.hasOwnProperty('lng_terms')
    }

    putTermsToLocalStorage(terms) {
        localStorage.setItem('lng_terms', JSON.stringify(terms))
    }

    getTermsFromLocalStorage() {
        return JSON.parse(localStorage.getItem('lng_terms'))
    }

    onPageLoaded() {

    }


}