<template>
    <div class="ui_modal_overlay" :class=position @click.self=close>

        <div class="ui_modal_close" @click=close>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="36" viewBox="0 0 24 24" width="36">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
        </div>

        <div class="ui_modal" :style="styles" :class=addclass>
            <div
                v-if="title"
                class="h2 ui_modal_title">
                {{ title }}
            </div>
            <slot></slot>
        </div>

    </div>
</template>


<script>
export default {
    name: 'Modal',
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        addclass: {
            type: String,
            default: ''
        },
        position: {
            type: String,
            default: 'center'
        },
        width: {
            type: Number,
            default: 540
        }
    },
    data(){
        return { }
    },
    computed: {
        styles() {
            return {
                width: this.width + 'px'
            }
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        freeze() {
            document.body.classList.add('ovh')
            document.body.addEventListener("touchmove", this.freezeVp, false)
        },
        unfreeze() {
            document.body.classList.remove('ovh')
            document.body.removeEventListener("touchmove", this.freezeVp, false)
        },
    },
    beforeMount() {
        this.freeze()
    },
    beforeUnmount() {
        this.unfreeze()
    },
    beforeDestroy() {
        this.unfreeze()
    },
    freezeVp: function(e) { e.preventDefault() }
}
</script>


<style lang="scss">

@import '../scss/utils/mixins.scss';
@import '../scss/utils/variables.scss';

.ui_modal_overlay{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 5001;
    bottom: 0;
    left: 0;
    right: 0;
    top: -70px;
    padding-bottom: 70px;
    transform: translateY(70px);
    outline: 0;
    @include break(sm) {
        align-items: center;
    }
    &.center{

    }
    &.right{
        align-items: flex-end;
    }
}
.ui_modal_title{
    font-family: $font-primary;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: $color-day-dark-gray;
    border-bottom: 1px solid $color-day-line;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 20px;
}
.ui_modal_close{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover{

    }
}
.ui_modal{
    position: relative;
    background: #fff;
    padding: 20px;
    overflow: hidden;
    overflow-y: scroll !important;
    min-height: 200px;
    max-height: 100vh;
    height: auto;
    width: 500px;
    max-width: 100%;
    border-radius: 10px;
    z-index: 9999;
    box-shadow: 0 50px 100px rgba(0, 0, 0, 0.25);
    -webkit-overflow-scrolling: touch;
    font-family: $font-secondary;
    font-size: 16px;
    color: $color-day-dark-gray;
    letter-spacing: 0;
    @extend .noScrollbar;
    @include break(sm) {
        padding: 20px;
    }
}
</style>
