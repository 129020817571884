export default class Stream {

    constructor(offer_id) {
        this.model = {
            stream_id: 0,
            stream_name: "",
            geo: null,
            offer_id: offer_id,
            landing_id: null,
            prelanding_id: null,
            facebook_id: "",
            facebook_track: "PageView",
            google_a_id: "",
            google_gtag_id: "",
            yandex_m_id: "",
            tt_id: "",
            utm_medium: "",
            utm_content: "",
            utm_campaign: "",
            utm_term: "",
            domain_landing: null,
            domain_prelanding: null,
            display_on_prelanding: 1,
            display_on_landing: 1,
            display_on_success: 1,
            facebook_id2: "",
            facebook_track2: "",
            lead_creation: "",
            lead_rejection: "",
            lead_confirmation: "",
            lead_trash: "",
            shopwindow_active: {
                success: false,
                comebacker: false,
            },
        }
    }


    getModel() {
        return this.model
    }

}



