<template>
    <div class="webmaster-header__profile-block">

        <div v-if="user" class="webmaster-header__profile_info">
            <p class="webmaster-header__profile_name">
              {{ user.username }}
            </p>
            <p class="webmaster-header__profile_id">
              {{ user.id }}
            </p>
        </div>

        <div id="wv-popup--button" class="webmaster-header__profile_arrow" tabindex="0">
            <img src="/images/header-profile-arrow.svg" alt="Open profile settings"/>
        </div>

        <div id="wv-settings-popup" class="wv-settings-popup">
            <div class="wv-settings-popup__header">
                <div v-if="statMini" class="wv-settings-popup__logo">
                    <img
                        :src="statMini.img_url"
                        alt="Webmaster avatar"/>
                </div>
                <div
                    v-if="user"
                    class="wv-settings-popup__info">
                    <h4 class="wv-settings-popup__name">
                        {{ user.username }}
                    </h4>
                    <p class="wv-settings-popup__email">
                        {{ user.email }}
                    </p>
                    <div class="wv-settings-popup__id">
                        ID {{ user.id }}
                    </div>
                </div>
            </div>

            <div class="pl-30 pr-30">
                <FormSelect
                    :label="false"
                    :showResetOption="false"
                    :withIcons="true"
                    v-model:selected="userLanguage"
                    :options="$lng.languages"
                ></FormSelect>
            </div>

            <ul class="wv-settings-popup__list">

                <li class="wv-settings-popup__item">
                    <router-link
                        class="wv-settings-popup__item_link"
                        :to="'/'+this.$route.params.lng+'/webmaster/support'"
                        v-slot="{href, route, navigate}">
                        <a
                            class="wv-settings-popup__item_link"
                            :href="href"
                            @click="navigate">
                            <div class="wv-settings-popup__item_logo">
                                <img src="/images/settings-tickets-logo.svg" alt="Support"/>
                            </div>
                            <div class="wv-settings-popup__item_text">
                                {{ $lng.__('lk_webmaster_user_menu', 'Tickets') }}
                                <div
                                    v-if="countTicketsUnread"
                                    class="wv-settings-popup__ticket_notification ticket-notificaitions">
                                    {{ countTicketsUnread }}
                                </div>
                            </div>
                        </a>
                    </router-link>
                </li>

                <li class="wv-settings-popup__item">
                    <router-link
                        class="wv-settings-popup__item_link"
                        :to="'/'+this.$route.params.lng+'/webmaster/shop/orders'"
                        v-slot="{href, route, navigate}">
                        <a
                            class="wv-settings-popup__item_link"
                            :href="href"
                            @click="navigate">
                            <div class="wv-settings-popup__item_logo">
                                <SvgUse :width="20" :height="20" id="cart"></SvgUse>
                            </div>
                            <p class="wv-settings-popup__item_text">
                                {{ $lng.__('lk_webmaster_user_menu', 'Webvorkshop orders') }}
                            </p>
                        </a>
                    </router-link>
                </li>

                <li class="wv-settings-popup__item">
                    <router-link
                        class="wv-settings-popup__item_link"
                        :to="'/'+this.$route.params.lng+'/webmaster/payouts'"
                        v-slot="{href, route, navigate}">
                        <a
                            class="wv-settings-popup__item_link"
                            :href="href"
                            @click="navigate">
                            <div class="wv-settings-popup__item_logo">
                                <img src="/images/settings-payouts-logo.svg" alt="Payouts"/>
                            </div>
                            <p class="wv-settings-popup__item_text">
                                {{ $lng.__('lk_webmaster_user_menu', 'Payouts') }}
                            </p>
                        </a>
                    </router-link>
                </li>

                <li class="wv-settings-popup__item">
                    <router-link
                        class="wv-settings-popup__item_link"
                        :to="'/'+this.$route.params.lng+'/webmaster/settings'"
                        v-slot="{href, route, navigate}">
                        <a
                            :href="href"
                            @click="navigate"
                            class="wv-settings-popup__item_link">
                            <div class="wv-settings-popup__item_logo">
                                <img src="/images/settings-user-settings-logo.svg" alt="Settings"/>
                            </div>
                            <p class="wv-settings-popup__item_text">
                                {{ $lng.__('lk_webmaster_user_menu', 'User settings') }}
                            </p>
                        </a>
                    </router-link>
                </li>

                <li class="wv-settings-popup__item">
                    <a
                        class="wv-settings-popup__item_link"
                        @click.prevent="$store.dispatch('auth/logout')"
                        href="#">
                        <div class="wv-settings-popup__item_logo">
                            <img
                                src="/images/settings-log-out-logo.svg"
                                alt="Ticket logo"
                            />
                        </div>
                        <p class="wv-settings-popup__item_text">
                            {{ $lng.__('lk_webmaster_user_menu', 'Log out') }}
                        </p>
                    </a>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
import {SettingPopup} from '@/components/Popup';

export default {
    name: 'WebmasterHeaderUserMenu',
    props: [],
    data() {
        return {
            settingPopup: false,
        };
    },
    computed: {
        statMini() {
            return this.$store.state.webmaster.statMini;
        },
        userLanguage: {
            get() {
                return this.user.language;
            },
            set(val) {
                this.$store.dispatch('auth/saveUser', {...this.user, language: val})
                    .then(() => {
                        this.$toast.success(this.$lng.__('lk_webmaster_user_settings','Successfully saved'));
                        this.$lng.setLanguage(val);
                    });
            }
        },
        user() {
            return this.$store.state.auth.user;
        },
        countTicketsUnread() {
            return this.$store.getters['auth/countTicketsUnread'];
        }
    },
    methods: {},
    mounted() {
        this.settingPopup = new SettingPopup('wv-settings-popup');
        this.settingPopup.init();
        this.$router.afterEach((to, from) => {
            this.settingPopup.hidePopup();
        });
    }
};
</script>

<style lang="scss">

@import '../../scss/utils/mixins.scss';
@import '../../scss/utils/variables.scss';

.wv-settings-popup {
    width: 415px;
    min-height: auto;
    display: none;
    flex-direction: column;
    border-radius: 10px;
    background-color: $color-day-white;
    box-shadow: 0px 50px 100px rgba($color-day-black, 0.25);
    position: absolute;
    top: 100px;
    right: 0;
    opacity: 0;
    z-index: $z-index-popup + 1;
    &__header {
        width: 100%;
        height: 160px;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__logo {
        width: 100px;
        height: 100px;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 30px;
        width: 100%;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
    }

    &__email {
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $color-day-form-shadow;
    }

    &__id {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        padding: 0 10px;
        border-radius: 15px;
        border: 1px solid $color-day-line;

        font-family: $font-secondary;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.005em;
        text-transform: uppercase;
    }

    &__list {
        width: 100%;
        margin-top: 10px;
    }

    &__item {
        width: 100%;
        height: 80px;
        background-color: transparent;
        &:nth-child(0) {
            border-top: 1px solid $color-day-bg;
            .wv-settings-popup__item_text {
                border-bottom: none;
            }
        }
        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            overflow: hidden;
            .wv-settings-popup__item_text {
                border-bottom: none;
            }
        }
        &_form {
            width: 100%;
            height: 100%;
        }

        &_link {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            border: none;
            font-family: $font-secondary;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 24px;
            align-items: center;
            letter-spacing: 1px;
            color: $color-day-lmenu-normal;
            transition: background-color 0.2s;
            &:hover {
                background-color: $color-day-table;
                & .wv-settings-popup__item_logo {
                    background-color: $color-day-white;
                }
            }
            svg{
                fill: $color-day-light-green;
            }

        }

        &_logo {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $color-day-bg;
            margin: 0 30px;

            transition: background-color 0.2s;

            img {
                width: 20px;
                height: 20px;
            }
        }

        &_text {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 30px;

            border-bottom: 1px solid $color-day-bg;
        }
    }

    &__ticket_notification {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        margin-left: auto;

        font-family: $font-secondary;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.5px;
    }

    &__country {
        &:hover {
            background-color: $color-day-table;
            & .wv-settings-popup__item_logo {
                background-color: $color-day-white;
            }
        }
        .wv-settings-popup__item_text {
            border-bottom: none;
        }
    }

    &--active {
        animation: show-wv-settings-popup 0.2s linear 0s both;
    }
}

</style>
