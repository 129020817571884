<template>
    <div class="webmaster-layout__container">

        <ScrollUp></ScrollUp>

        <template v-if="!$route.meta.isInternal">
            <BaseAuth></BaseAuth>
        </template>

        <template v-if="$route.meta.isInternal">
            <WebmasterSidebar
                v-if="$store.state.webmaster"
            ></WebmasterSidebar>

            <div class="webmaster-layout__wrapper">

                <WebmasterHeader></WebmasterHeader>

                <AlertAccountNotActivated></AlertAccountNotActivated>

                <AccountNotActivated></AccountNotActivated>

                <router-view
                    v-if="$store.state.auth.user"
                ></router-view>

                <WebmasterFooter></WebmasterFooter>

                <SocialsModal></SocialsModal>

<!--                <ArabHolidaysModal></ArabHolidaysModal>-->

                <AlertModal></AlertModal>
                
            </div>
        </template>

    </div>
</template>

<script>
import WebmasterHeader from './WebmasterHeader.vue';
import WebmasterFooter from './WebmasterFooter.vue';
import WebmasterSidebar from './WebmasterSidebar.vue';
import AlertAccountNotActivated from './Widgets/AlertAccountNotActivated.vue';
import AccountNotActivated from './Widgets/AccountNotActivated.vue';
import BaseAuth from './Auth/BaseAuth.vue';
import moduleWebmaster from '../../store/moduleWebmaster.js';
import SocialsModal from './Widgets/SocialsModal.vue';
import ArabHolidaysModal from './Widgets/ArabHolidaysModal.vue';
import AlertModal from './Widgets/AlertModal.vue';

export default {
    name: 'Webmaster',
    props: {},
    data() {
        return {
            storePrepared: false,
        };
    },
    computed: {},
    methods: {
        checkIsMobile() {
            this.$store.dispatch('checkIsMobile');
        },
        redirectToPollIfNeed() {
            if (this.$store.state.auth.user && !this.$store.getters['auth/isUserPollPassed']) {
                this.$router.push('/' + this.$route.params.lng + '/webmaster/poll');
            }
        },
        debounce(func, timeout = 1000){
            let timer;
            return (...args) => {
                clearTimeout(timer);
                timer = setTimeout(() => { func.apply(this, args); }, timeout);
            };
        },
    },
    beforeMount() {
        let hasToken = localStorage.hasOwnProperty('token');
        if (!hasToken && !WV.isAuthRoute()) {
            this.$router.push('/' + this.$config.languageShort + '/auth/login');
        }
        this.$store.dispatch('shop/loadOrderFromLocalStorage');
    },
    watch: {
        $route(to, from) {
            document.title = to.meta.title || 'Webvork';
            if (!WV.isAuthRoute()) {
                this.redirectToPollIfNeed();
            }
            WV.Bus.emit('closeSidebar');
        },
        user: {
            deep: true,
            handler(user, oldUser) {
                this.redirectToPollIfNeed();
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.checkIsMobile);
        window.addEventListener('resize', this.debounce(() => {
            console.log('resize');
            WV.Bus.emit('resize');
        }));
        this.redirectToPollIfNeed();
    },
    components: {
        WebmasterSidebar,
        WebmasterFooter,
        WebmasterHeader,
        BaseAuth,
        AlertAccountNotActivated,
        AccountNotActivated,
        ArabHolidaysModal,
        SocialsModal,
        AlertModal
    }
};
</script>

<style lang="scss">

@import '../../scss/utils/mixins.scss';
@import '../../scss/utils/variables.scss';

.webmaster-layout {
    &__container {
        width: 100%;
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: row;
        background-color: $color-day-bg;
    }

    // Wrapper
    &__wrapper {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-day-bg;
        padding-left: 100px;
        padding-right: 30px;
        padding-bottom: 30px;
        overflow: hidden;
        font-family: 'Montserrat';
        font-style: normal;
        color: $color-day-dark-gray;

        @include minWidth(2060) {
            padding-left: 30px;
            margin-left: 275px;
        }

        @include maxWidth(950) {
            padding-left: 90px;
            padding-right: 20px;
            padding-bottom: 35px;
        }

        @include maxWidth(720) {
            padding-left: 20px;
            margin-left: 0;
        }

        @include maxWidth(475) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // Content
    &__content {
        width: 100%;
        height: 100%;
        min-height: 100%;
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: flex-start;
        color: $color-day-black;
        position: relative;
        // overflow: hidden;

        @include maxWidth(720) {
            padding-top: 90px;
        }
    }
}

// Mobile menu
.webmaster-layout-header--hide {
    @include maxWidth(720) {
        transform: translateY(-70px);
    }
}

</style>
