// КЛАСС Burger menu
export class BurgerMenu {
    constructor(name) {
        this.name = name
        this.menu = document.getElementById(name)
        this.burger = document.getElementById(`${name}_burger`)
    }

    addEventBurger() {
        if (!this.burger) return

        this.burger.addEventListener('click', () => {
            this.toggleMenu()
        })
    }

    clear() {
        window.addEventListener('resize', () => {
            if (window.innerWidth >= 720) {
                this.menu.classList.remove(`${this.name}--active`)
                this.burger.classList.remove(`${this.name}_burger--active`)
            }
        })
    }

    toggleMenu() {
        this.menu.classList.toggle(`${this.name}--active`)
        this.burger.classList.toggle(`${this.name}_burger--active`)
    }

    init() {
        if (!this.menu) return
        this.clear()
        this.addEventBurger()
    }
}

// Класс Mobile Header
export class MobileHeader {
    constructor(name) {
        this.name = name
        this.header = document.getElementById(this.name)
        this.show = true
    }

    addScrollEvent() {
        let startPoint = 0;

        document.addEventListener('scroll', () => {
          if (window.innerWidth > 720) return
            const scrollPoint = pageYOffset

            if (startPoint < scrollPoint && pageYOffset > 20) {
                if (this.show) {
                    this.hideHeader()
                }

            } else if (startPoint > scrollPoint) {
                if (!this.show) {
                    this.showHeader()
                }
            }

            startPoint = scrollPoint
        })
    }

    showHeader() {
        this.header.classList.remove(`${this.name}--hide`)
        this.show = true
    }

    hideHeader() {
        this.header.classList.add(`${this.name}--hide`)
        this.show = false
    }

    init() {
        if (!this.header) return
        this.addScrollEvent()
    }
}

export class IsOpenMenu {
    constructor() {
        this.name = 'wv_menu_isopen'
        this.isOpen = document.getElementById('webmaster-layout__menu--isopen')
    }

    toggleIsOpen() {
        this.isOpen.addEventListener('change', () => {
            this.toggleValue()
        })
    }

    toggleValue() {
        let isOpenValue = this.checkCookie()
        
        if (isOpenValue) {
            isOpenValue = !parseInt(isOpenValue)
            return document.cookie = `${this.name}=${isOpenValue ? 1 : 0}; path=/; domain=${document.location.hostname}`
        }

        document.cookie = `${this.name}=1; path=/; domain=${document.location.hostname}`
    }

    checkCookie() {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + this.name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    init() {
        if (!this.isOpen) return
        this.toggleIsOpen()
    }
}
