<template>

  <template v-if="isVisible">
    <Modal
      :title="$lng.__('lk_webmaster_errors', 'Account not activated')"
      class="account-not-activated"
      v-if="showModalActivateAccount"
      @close="showModalActivateAccount = false"
    >
      <p class="account-not-activated__text">
        {{
          $lng.__(
            'lk_webmaster_header',
            'Please contact your manager to activate your account!'
          )
        }}
      </p>

      <div class="dashboard__managers">
        <div class="dashboard__managers_info-block">
          <div class="dashboard__managers_avatar">
            <img :src="manager.image_url" :alt="manager.name" />
          </div>
          <div class="dashboard__managers_info">
            <h4 class="dashboard__managers_name">
              {{ manager.name }}
            </h4>
            <p class="dashboard__managers_status">
              {{ $lng.__('lk_webmaster_dashboard', 'Personal manager') }}
            </p>
          </div>
        </div>
        <div class="dashboard__managers_social">
          <a
            v-if="manager.email"
            target="_blank"
            :href="'mailto:' + manager.email"
            class="dashboard__managers_social_btn"
          >
            <div class="dashboard__managers_social-icon">
              <SvgUse
                :width="29"
                :height="29"
                id="gmailLogoColor"
                key="gmailLogoColor"
              ></SvgUse>
            </div>
          </a>
          <a
            v-if="manager.telegram"
            target="_blank"
            :href="'https://t.me/' + manager.telegram"
            class="dashboard__managers_social_btn"
          >
            <div class="dashboard__managers_social-icon">
              <SvgUse :width="29" :height="29" id="telegramLogoColor"></SvgUse>
            </div>
          </a>
          <a
            v-if="manager.skype"
            target="_blank"
            :href="'skype:' + manager.skype"
            class="dashboard__managers_social_btn"
          >
            <div class="dashboard__managers_social-icon">
              <SvgUse
                :width="29"
                :height="29"
                id="skypeLogoColor"
                key="skypeBtn"
              ></SvgUse>
            </div>
          </a>
          <a
            v-if="manager.whatsapp"
            target="_blank"
            :href="'https://wa.me/' + manager.whatsapp"
            class="dashboard__managers_social_btn"
          >
            <div class="dashboard__managers_social-icon">
              <SvgUse
                :width="29"
                :height="29"
                id="whatsappLogoColor"
                key="whatappBtn"
              ></SvgUse>
            </div>
          </a>
        </div>
      </div>
    </Modal>
  </template>

</template>

<script>
  import Modal from '@/components/Modal.vue';

  export default {
    name: 'AccountNotActivated',
    components: { Modal },
    data() {
      return {
        isVisible: false,
        showModalActivateAccount: true,

      };
    },
    computed: {
        manager() {
            return this.$store.state.webmaster.manager;
        },
        user() {
            return this.$store.state.auth.user;
        },
        isVisible() {
             return this.$store.getters['auth/isUserLoaded'] && !this.$store.getters['auth/isUserApproved'] && this.$store.getters['auth/isUserPollPassed']
        },
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
    beforeMount() {
      this.$store.dispatch('webmaster/getManagerInfo');
    },
  };
</script>

<style lang="scss">
  @import '../../../scss/utils/mixins.scss';
  @import '../../../scss/utils/variables.scss';

  .account-not-activated {
    &__text {
      padding: 20px 20px 0;
    }
  }
  .dashboard__managers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include break(xxl) {
      flex-direction: column;
    }
    &-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      order: 2;
      @include break(md) {
        order: 3;
        height: auto;
        position: relative;
        width: 100%;
      }
      @include break(xxl) {
        order: 2;
        height: 332px;
      }
    }
    &_info-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      @include break(lg) {
        padding: 30px;
      }
      @include break(xxl) {
        border-right: 0;
        padding-bottom: 50px;
      }
    }
    &_avatar {
      @include squareBlock(80px);
      margin-right: 30px;
      position: relative;
      z-index: $z-index-avatar;
      @include break(lg) {
        @include squareBlock(110px);
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      &_notification {
        @include squareBlock(30px);
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid $color-day-white;
        z-index: $z-index-avatar + 1;

        font-family: $font-secondary;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.5px;
        @include maxWidth(1526) {
          @include squareBlock(40px);
          right: 5px;
          bottom: 5px;
        }
        @include maxWidth(1250) {
          @include squareBlock(30px);
          right: 0;
          bottom: 0;
        }
      }
    }

    &_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @include maxWidth(1580) {
        width: 100%;
      }
      @include maxWidth(450) {
        margin-left: 20px;
      }
    }
    &_name {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.005em;
    }
    &_status {
      font-family: $font-secondary;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.5px;
      text-align: center;
      color: $color-day-light-gray;
      @include maxWidth(1100) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &_send-ticket {
      margin-top: 10px;
      line-height: 18px;
      width: 100%;
    }
    &_social {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 15px;
      border-top: 1px solid $color-day-line;
      flex-wrap: nowrap;
      @include break(xxl) {
        padding-top: 25px;
      }
      &_btn {
        width: 100%;
        min-height: 100%;
        display: flex;
        align-self: stretch;
        justify-content: center;
        align-items: center;
        padding: 5px;
        &:hover .dashboard__managers_social-icon {
          box-shadow: 0 10px 20px rgba(109, 175, 253, 0.25);
        }
      }
      &-icon {
        height: 55px;
        min-height: 55px;
        min-width: 55px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-day-line;
        border-radius: 20px;
        padding: 12px;
        transition: background-color 0.2s, box-shadow 0.2s;

        img {
          flex: none;
          min-height: 100%;
          max-height: 100%;
        }
      }
    }
  }
</style>
